import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiMmpTagRel = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getMmpTagRelList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagRel.post("/admin/gmmptrl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getMmpTagRel(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiMmpTagRel.get("/admin/gmmptr/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createMmpTagRel(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagRel.post("/admin/cmmptr/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateMmpTagRel(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagRel.put("/admin/ummptr/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteMmpTagRel(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagRel.delete("/admin/dmmptr/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteMmpTagRel2(tag1id, tag2id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagRel.delete("/admin/dmmptr2/" + tag1id + "/" + tag2id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
