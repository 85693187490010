<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="add"
      headerTxt="Module Operation"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="receiveFormValues"
    />
  </div>
</template>
<script>
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import amoService from "@/services/appModuleOperationService.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppModuleOperationAdd",
  components: {
    genericDialogForm,
  },
  props: ["msg"],
  data() {
    return {
      objFields: [
        { name: "remarks", label: "remarks", type: "string", value: "" },
        {
          name: "ApplicationModule_id",
          label: "app module",
          type: "fk_int",
          dataSrc: "ApplicationModule",
          value: "",
        },
        {
          name: "Operation_id",
          label: "operation",
          type: "fk_int",
          dataSrc: "Operation",
          value: "",
        },
        { name: "url", label: "url", type: "text", value: "" },
        { name: "method", label: "method", type: "text", value: "" },
        { name: "params", label: "params", type: "text", value: "" },
      ],
      submitButton: { target: "" },
      dataReady: false,
    };
  },
  created() {
    this.$_log("views AppModuleOperation:add new");
    this.dataReady = true;
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  methods: {
    ...mapActions("appModOp", ["insertAppModuleOperation"]),
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    receiveFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      // send data to the API server ...
      const obj = {
        remarks: this.objFields[0].value,
        ApplicationModule_id: this.objFields[1].value,
        Operation_id: this.objFields[2].value,
        url: this.objFields[3].value,
        method: this.objFields[4].value,
        params: this.objFields[5].value,
        user_created: this.currentUserId,
      };

      this.$_log("AppModuleOperation:add new doSubmit - objectToBeSent:", obj);

      amoService
        .insertAppModuleOperation(obj)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!

          var n = {
            type: "success",
            message: "You are correctly created new module operation.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "appModuleOperationView",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
  },
};
</script>

<style scoped>
.dlg_form_container {
  height: 550px;
  /*translate: -50% -30%;*/
}
</style>
