<template>
  <div
    class="menu-item"
    @click.prevent="isOpen = !isOpen"
    v-click-out-side="asw_clickedOutsideOfTheMenu"
  >
    <a href="#">
      {{ title }}
    </a>
    <svg viewBox="0 0 1030 638" width="10">
      <path
        d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z"
        fill="#FFF"
      ></path>
    </svg>

    <transition name="fade" appear>
      <div :style="chekIfProfile(name)" class="sub-menu" v-if="isOpen">
        <div v-for="(item, i) in items" :key="i" class="menu-item">
          <!-- ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ -->
          <a v-if="item.link != ''" href="#" @click.prevent="item.fun"
            ><img
              v-if="item.icon != ''"
              :src="require('@/assets/' + item.icon)"
            />{{ item.title }}</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import { mapGetters } from "vuex";

export default {
  name: "DropdownMenu",
  props: ["title", "items", "name"],
  data() {
    return {
      isOpen: false,
    };
  },
  directives: {
    clickOutSide,
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
    ]),
  },
  methods: {
    asw_clickedOutsideOfTheMenu() {
      //console.log("You clicked outside of the menu box!");
      if (this.isOpen == true) this.isOpen = false;
    },
    chekIfProfile(n) {
      this.$_log("In case of profile menu move to the left menuName:" + n);
      if (n == "userProfile") {
        return "left: -160px";
      } else {
        return "";
      }
    },
  },
};
</script>

<style scoped>
nav > .menu-item {
  display: inline-block;
  padding: 0 0px 0 10px;
}
div .menu-item svg {
  width: 10px;
  margin-left: 5px;
}
div .menu-item .sub-menu {
  position: absolute;
  background-color: #5c6670;
  top: calc(100% + 25px);
  /*left: 200px;*/
  /*right: 0%;*/
  /*transform: translateX(20px);*/
  width: 260px;
  /*padding-left: 40px;*/

  /*border-radius: 0px 0px 16px 16px;*/
}

.sub-menu {
  z-index: 1000;
}
.sub-menu .menu-item {
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 2px;
  text-align: left;
}

.menu-item a {
  text-align: left;
  font: normal normal 200 14px/21px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding-left: 5px;
  cursor: pointer;
}

.menu-item a img {
  margin-right: 5px;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
