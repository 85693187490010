<template>
  <div>
    <label :for="uuid">{{ label }}</label>
  </div>

  <div>
    <input
      :name="name"
      :value="value"
      :placeholder="placeholder"
      :type="type"
      class="field"
      @input="$emit('update:name', $event.target.value)"
      :id="uuid"
      :aria-describedby="error ? `${uuid}-error` : null"
      :aria-invalid="error ? true : null"
    />
    <p
      v-if="error"
      class="errorMessage"
      :id="`${uuid}-error`"
      aria-live="assertive"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import UniqueID from "@/features/UniqueID";

export default {
  props: {
    label: { type: String, required: true },
    modelValue: { type: [String, Number], default: "" },
    name: { type: String, required: true },
    value: { type: [String, Number], default: "" },
    type: { type: [String], default: "text" },
    placeholder: { type: [String, Number], default: "" },
    error: { type: String, default: "" },
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
};
</script>

<style scoped></style>
