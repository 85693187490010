<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="edit"
      :id="id"
      headerTxt="User Type"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="receiveFormValues"
    />
  </div>
</template>

<script>
import userTypeSrv from "@/services/userTypeService.js"; // access to the APIs
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserTypeEdit",
  components: { genericDialogForm },
  props: ["id"],
  data() {
    return {
      objFields: [
        {
          name: "name",
          label: "user type",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "remarks",
          label: "remarks",
          type: "text",
          value: "",
          toBeSend: 1,
        },
      ],
      UserType: null,
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  created() {
    this.$_log("11 views getUserType layout edit utId:" + this.id);
    userTypeSrv
      .getUserType(this.id)
      .then((r) => {
        this.$_log("11 Just after getUserType....");
        this.$_log("11 created response:", r.data.data[0]);
        this.UserType = r.data.data[0];
        this.fillCurrObjWithData(r.data.data[0]);
        this.dataReady = true;
      })
      .catch((error) => {
        this.$_log(error);
      });
  },
  methods: {
    receiveFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      // send data to the API server ...
      const obj = {
        id: this.id,
        name: this.objFields[0].value,
        remarks: this.objFields[1].value,
        user_id: this.currentUserId, // <- getter
      };

      this.$_log("UserType:update doSubmit ... obj", obj);

      userTypeSrv
        .updateUserType(obj)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!

          var n = {
            type: "success",
            message: "You just correctly updated User Type data.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "userTypes",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
    fillCurrObjWithData(d) {
      for (var i = 0; i < this.objFields.length; i++) {
        for (const [key, value] of Object.entries(d)) {
          //this.$_log(key + " =====> " + value);
          if (this.objFields[i].name == key) {
            //this.$_log("OOOOOK: " + key + " =====> " + value);
            this.objFields[i].value = value;
          }
        }
      }
    },
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
  },
};
</script>
<style scoped></style>
