<template>
  <div class="idf_container">
    <div class="idf_cl1">
      <p class="idf_header">
        <a @click="goBack">&lt;&lt;</a> Account settings{{ msg }}
      </p>
    </div>
    <div class="idf_cl2">
      <form @submit.prevent="onSubmit">
        <div>
          <label>Name</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.name"
            type="text"
            placeholder="Your Name"
          />
        </div>
        <div>
          <label>E-mail address</label>
        </div>
        <div>
          <input v-model="mCurrentUser.email" type="text" />
        </div>

        <div>
          <label>First name</label>
        </div>
        <div>
          <input v-model="mCurrentUser.first_name" type="text" />
        </div>

        <div>
          <label>Last name</label>
        </div>
        <div>
          <input v-model="mCurrentUser.last_name" type="text" />
        </div>

        <div class="idf_submit_line">
          <button type="submit" class="idf_submit_btn">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import userService from "@/services/userService.js"; // access to the APIs
//import DropDownList from "@/components/dialogs/formElements/DropDownList.vue";

export default {
  name: "UserDetails",
  props: ["msg", "id"],
  /*
  components: {
    DropDownList,
  },
  */
  data() {
    return {
      user: null,
      mCurrentUser: {
        user_id: this.currentUserId,
        name: "",
        email: "",
        UserType_id: "",
        userStatus_id: "",
        remarks: "",
        first_name: "",
        last_name: "",
      },
    };
  },
  created() {
    /*
    this.$_log(
      "User [" +
        this.currentUserId +
        "] Details TOKEN >>>>" +
        this.$store.state.userModule.currentUser.token
    );
	*/
    if (this.isUserLoggedIn) {
      // hmm but why the hell should it check it ???

      userService
        .getUserDetails(this.currentUserId)
        .then((response) => {
          this.$_log("asw response.data:", response.data.user_data[0]);
          this.mCurrentUser = response.data.user_data[0];
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    }
  },
  computed: {
    ...mapGetters("userModule", [
      "userToken",
      "isUserLoggedIn",
      "currentUserId",
    ]),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    setUserStatus(v) {
      this.$_log("setUserStatus elName:" + v.name + " elValue:" + v.value);
      this.mCurrentUser.userStatus_id = v.value;
      this.$_log(
        "setUserStatus this.mCurrentUser.userStatus_id:" +
          this.mCurrentUser.userStatus_id
      );
    },
    onFn001(v) {
      this.$_log("onFn001 elName:" + v.name + " elValue:" + v.value);
      //this.$_log("onFn001 elValue:" + v);
    },
    onSubmit() {
      const usr = {
        user_id: this.currentUserId, // <- getter
        name: this.mCurrentUser.name,
        email: this.mCurrentUser.email,
        UserType_id: this.mCurrentUser.UserType_id,
        userStatus_id: this.mCurrentUser.userStatus_id,
        remarks: this.mCurrentUser.remarks,
        first_name: this.mCurrentUser.first_name,
        last_name: this.mCurrentUser.last_name,
      };

      this.$_log("UserDetails.vue] onSubmit - User:", usr);
      userService
        .setUserDetails(usr)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //this.user = response.data.user_data[0];
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
  },
};
</script>

<style scoped></style>
