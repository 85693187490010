<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="add"
      headerTxt="User Status"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="validateFormValues"
    />
  </div>
</template>
<script>
import userStatusSrv from "@/services/userStatusService.js"; // access to the APIs
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "UserStatusAdd",
  components: {
    genericDialogForm,
  },
  props: ["msg"],
  data() {
    return {
      objFields: [
        {
          name: "name",
          label: "user status",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "remarks",
          label: "remarks",
          type: "text",
          value: "",
          toBeSend: 1,
        },
      ],
      submitButton: { target: "" },
      dataReady: false,
    };
  },
  created() {
    this.$_log("views UserStatus:add new");
    this.dataReady = true;
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    goBack() {
      this.$router.go(-1);
    },
    onFn001(v) {
      this.$_log("onFn001 elName:" + v.name + " elValue:" + v.value);
      //this.$_log("onFn001 elValue:" + v);
    },
    validateFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      const obj = {
        name: this.objFields[0].value,
        remarks: this.objFields[1].value,
        user_created: this.currentUserId,
      };

      this.$_log("userStatus:add new onSubmit - UserStatus:", obj);

      userStatusSrv
        .createUserStatus(obj)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!

          var n = {
            type: "success",
            message: "You just correctly created new user status.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "userStatuses",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
  },
};
</script>

<style scoped></style>
