<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <form @submit.prevent="onSubmit">
        <div>
          <label>Name</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.name"
            type="text"
            placeholder="Your Name"
          />
        </div>
        <div>
          <label>E-mail address</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.email"
            type="text"
            placeholder="test@test.com"
          />
        </div>

        <div>
          <label>Password</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.password"
            type="password"
            placeholder="password"
            class="dlg_pwd"
          />
        </div>
        <div>
          <label>Confirm password</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.passwordC"
            type="password"
            placeholder="password"
            class="dlg_pwd"
          />
        </div>
        <div class="dlg_msg">
          <p>
            Do you want to log in?<br />
            <router-link to="/login">Go to log in page</router-link>
          </p>
        </div>
        <!--
		<div style="position: relative">
          <button type="submit" class="dlg_submit">Register</button>
        </div>
		-->

        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn">Register</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserRegistration",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        name: "",
        username: "",
        email: "",
        password: "",
        passwordC: "",
      },
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    onSubmit() {
      const usr = {
        name: this.mCurrentUser.name,
        email: this.mCurrentUser.email,
        password: this.mCurrentUser.password,
      };

      if (
        usr.name != "" &&
        usr.email != "" &&
        usr.email != "test@test.com" &&
        usr.password != ""
      ) {
        this.$_log("UserRegistration.vue] onSubmit - User:", usr);
        this.$store
          .dispatch("userModule/registerTestUser", usr)
          .then(() => {
            this.$_log(
              "UserRegistration.vue] onSubmit - then registered email:" +
                this.currentUsername +
                " user_id:" +
                this.currentUserId +
                " user.token:" +
                this.userToken
            );
            /*
          this.$router.push({
            name: "UserView",
            params: { id: this.currentUserId },
          });
		  */
          })
          .catch((error) => {
            this.$_log(error);
            /*
			this.$router.push({
			  name: "ErrorDisplay",
			  params: { error: error },
			});*/
          });
      } else {
        var n = {
          type: "problem",
          message: "Fileds name, email, password need to be filled.",
        };
        this.addNotificationMessage(n);
      }
    },

    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", ["login"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped>
.dlg_form_container {
  height: 420px;
}
</style>
