<template>
  <div v-if="this.importingUsers == true" class="loading"></div>
  <div v-if="dataReady" class="userViewContainer">
    <listHeaderAdditional
      msg="importDataFromCognito"
      @getCognitoUsers="callGetCognitoUsers"
    />
    <listHeader
      pLabel="user Lbl"
      pLinkName="userAdd"
      dataSrc="User"
      :searchVars="searchVariables"
      @searchValues="Search"
    />

    <div class="tabDataContainer">
      <table>
        <tabHeader
          :tableFields="tabFields"
          :fieldsToHide="tabFieldsToHide"
          :fieldsClass="tabFieldsClass"
          :fieldsTranslation="tabFieldsTranslations"
        />

        <tr
          v-for="(u, u_index) in tableContent"
          :key="u.id"
          :class="computedCssClass(u_index)"
        >
          <td>{{ u_index + 1 }}</td>
          <td>{{ u.name }}</td>
          <td>{{ u.remarks }}</td>
          <td>{{ u.UserStatus }}</td>
          <td>
            <template v-if="u.Client_id != 0">
              <!--
				<router-link :to="'/client/0/' + u.Client_id">{{
                u.UserType
              	}}</router-link>
			  	-->
              <router-link
                :to="{
                  name: 'clientEdit',
                  params: { id: u.Client_id },
                }"
                >{{ u.UserType }}
              </router-link>
            </template>
            <template v-else>{{ u.UserType }}</template>
          </td>
          <td>{{ u.email }}</td>
          <!-- <td>{{ u.firstname }} {{ u.lastname }}</td> -->

          <td>
            <router-link :to="{ name: 'userEdit', params: { id: u.id } }"
              >edit</router-link
            >
            /
            <a
              @click="deleteElement($event, u.id, u.name)"
              style="cursor: pointer"
            >
              delete
            </a>
          </td>
        </tr>
      </table>
    </div>

    <pagination
      :pageNr="pageNr"
      :totalPages="totalPages"
      :nrRowsTotal="nrRowsTotal"
      linkName="userView"
      :nrEOP="nrElOnPage"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { watchEffect } from "vue";
import userService from "@/services/userService.js"; // access to the APIs
import pagination from "@/components/listView/tabPagination.vue";
import listHeader from "@/components/listView/listHeader.vue";
import listHeaderAdditional from "@/components/listView/listHeaderAdditional.vue";

import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";
import tabHeader from "@/components/listView/tabHeader.vue";

export default {
  name: "UserView",
  components: {
    listHeader,
    pagination,
    tabHeader,
    listHeaderAdditional,
  },

  props: ["id", "msg", "pageNr"],
  data() {
    return {
      users: null,

      usertype: null,
      tableName: "User",
      tableContent: null,

      tabFields: null,
      tabFieldsToHide: [],
      tabFieldsClass: [],
      tabFieldsTranslations: [],

      dataReady: false,
      importingUsers: false,
      /*
      paginationParams: {
        pageNr: this.pageNr,
        totalPages: Math.ceil(this.nrRowsTotal / this.nrElOnPage),
        nrRowsTotal: this.nrRowsTotal,
        linkName: "userView",
        nrElOnPage: this.nrElOnPage,
      },
	  */

      // --> fields to be search
      searchVariables: {
        username: {
          label: "username",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        first_name: {
          label: "first name",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        last_name: {
          label: "last name",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        UserType_id: {
          label: "usertype",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        userStatus_id: {
          label: "status",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        user_created: {
          label: "created by",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        time_created: {
          label: "created",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        loggedin: {
          label: "logedin",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        email: {
          label: "email",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        remarks: {
          label: "remarks",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
      },
      // --< fields to be search
      nrRowsTotal: 0,
      nrElOnPage: 10,
      orderBy: "id",
    };
  },
  created() {
    this.$_log("pageNr:" + this.pageNr);
    //this.$_log("TOKEN >>>>" + this.$store.state.userModule.currentLoggedUser.token);
    this.$_log("TOKEN >>>>" + this.userToken);
    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.nrRowsTotal / this.nrElOnPage);
    },
    ...mapGetters("userModule", ["userToken", "isUserLoggedIn"]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    callGetCognitoUsers() {
      this.$_log("callGetCognitoUsers ....");
      this.importingUsers = true;
      userService
        .getCognitoUsers()
        .then((response) => {
          this.importingUsers = false;
          this.$_log("asw response.data:", response.data);

          var n = {
            type: "success",
            message: "You just correctly imported user data from cognito...",
          };
          this.addNotificationMessage(n);
        })
        .then(() => {
          this.$router.push({
            name: "userView",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.importingUsers = false;
          this.$_log("asw error:", error);
        });
    },
    lookForClient(clientId) {
      this.$router.push({
        name: "clientEdit",
        params: { id: clientId },
      });
    },
    deleteElement(event, elemId, elemName) {
      this.$_log("event:" + event);
      this.$_log("delete elem:" + elemId);
      ///
      useConfirmBeforeAction(
        function () {
          //alert("user confirmed!");

          userService
            .deleteUser(elemId)
            .then((response) => {
              this.$_log("asw response.data:", response.data);

              var n = {
                type: "success",
                message: "You just correctly removed user '" + elemName + "'",
              };
              this.addNotificationMessage(n);
            })
            .then(() => {
              this.getData();
              /*
              this.$router.push({
                name: "userView",
                params: { pageNr: 0 },
              });*/
            })
            .catch((error) => {
              this.$_log("asw error:", error);
            });
        }.bind(this),
        function () {
          this.$_log("action canceled ...");
        }.bind(this),
        {
          question: "Do you realy wnat to delete user `" + elemName + "` ?",
        }
      );
      ///
      /**/
    },
    Search(data) {
      this.$_log("Search data:", data);
      this.searchVariables = data;
      this.getData();
    },
    getData() {
      var jo = {
        // search params ->
        name: this.searchVariables.username.value,
        remarks: this.searchVariables.remarks.value,
        user_created: this.searchVariables.user_created.value,
        time_created: this.searchVariables.time_created.value,
        userStatus_id: this.searchVariables.userStatus_id.value,
        UserType_id: this.searchVariables.loggedin.value,
        loggedin: this.searchVariables.remarks.value,
        email: this.searchVariables.email.value,
        first_name: this.searchVariables.first_name.value,
        last_name: this.searchVariables.last_name.value,
        // search params <-
        pageNr: this.pageNr,
        nrElOnPage: this.nrElOnPage,
        orderBy: this.orderBy,
      };
      userService
        .getUsers(jo)
        .then((r) => {
          this.$_log("Just before getUsers....");
          this.$_log("created response:", r.data);

          this.nrRowsTotal = r.data.nrRowsTotal;
          this.tableContent = r.data.data;

          this.tabFields = r.data.fields;
          this.tabFieldsToHide.push(
            { name: "user_created" },
            { name: "time_created" },
            { name: "loggedin" },
            { name: "first_name" },
            { name: "last_name" },
            { name: "Client_id" }
          );
          this.tabFieldsClass.push({ name: "remarks", class: "wideTabColumn" });
          this.tabFieldsTranslations.push({
            name: "functions",
            value: "FUNKCJE",
          });

          this.$_log(
            "created[" +
              this.tableName +
              "] this.tableContent.length:" +
              this.tableContent.length +
              " nrRowsTotal:" +
              this.nrRowsTotal
          );

          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    computedCssClass(i) {
      if (i % 2 === 0) {
        return "";
      } else {
        return "odd";
      }
    },
  },
};
</script>

<style scoped>
.loading {
  background: transparent
    url("https://miro.medium.com/max/882/1*9EBHIOzhE1XfMYoKz1JcsQ.gif") center
    no-repeat;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.5;
}
</style>
