import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiMmpTag = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getMmpTagList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTag.post("/admin/gmmptl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getMmpTag(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiMmpTag.get("/admin/gmmpt/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createMmpTag(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTag.post("/admin/cmmpt/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateMmpTag(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTag.put("/admin/ummpt/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteMmpTag(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTag.delete("/admin/dmmpt/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
