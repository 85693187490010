import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiModuleSection = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getModuleSectionList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleSection.post("/admin/gmdsl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getModuleSection(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiModuleSection.get("/admin/gmds/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createModuleSection(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleSection.post("/admin/cmds/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateModuleSection(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleSection.put("/admin/umds/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteModuleSection(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleSection.delete("/admin/dmds/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
