<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <form @submit.prevent="onSubmit">
        <div>
          <label
            >Enter the e-mail address to which the link to set password is to be
            sent.</label
          >
        </div>
        <div>
          <label>E-mail address</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.email"
            type="text"
            placeholder="test@test.com"
          />
        </div>

        <div class="dlg_msg">
          <p>
            Do you want to log in?<br />
            <router-link to="/login">Go to log in page</router-link>
          </p>
        </div>
        <!--
		<div style="position: relative">
          <button type="submit" class="dlg_submit">Send</button>
        </div>
		-->
        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn">Send</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResetPasswordRequestDialog",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        email: "",
      },
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    onSubmit() {
      const usr = {
        email: this.mCurrentUser.email,
      };

      if (usr.email == "" || usr.email == "test@test.com") {
        var n = {
          type: "problem",
          message: "Please fill email filed.",
        };
        this.addNotificationMessage(n);
      } else {
        this.$_log("ResetPasswordRequest.vue] onSubmit - User:", usr);
        this.$store
          .dispatch("userModule/resetPasswordRequest", usr)
          .then(() => {
            this.$_log(
              "ResetPasswordRequest.vue] onSubmit - then registered email:" +
                usr.email
            );

            this.$router.push({
              name: "resetPasswordRequest",
            });
          })
          .catch((error) => {
            this.$_log("ResetPasswordRequest onSubmit error:", error);
            /*
			  this.$router.push({
				name: "ErrorDisplay",
				params: { error: error },
			  });*/
          });
      }
    },
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userModule", ["login"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped></style>
