<template>
  <template v-if="dataReady">
    <label :for="uuid">{{ listLabel }}</label>

    <select
      :id="uuid"
      v-model="selectedItem"
      :name="elName"
      :size="elSize"
      :style="elStyle"
      @change="onChange($event)"
      :class="elName"
    >
      <option value="">--</option>
      <option
        v-for="(t, index) in tableContent"
        :key="index"
        :value="t.id"
        :selected="t.id === selectedItem"
      >
        {{ t.name }}
      </option>
    </select>
  </template>
</template>

<script>
import UniqueID from "@/features/UniqueID";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DropDownList",
  emits: ["selectUpdated"],
  props: {
    listLabel: String,
    srcTabName: String,
    selected: [String, Number],
    elName: String,
    elSize: Number,
    elStyle: String,
  },
  data() {
    return {
      tableName: "",
      tableContent: null,
      selectedItem: "",
      dataReady: false,
    };
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
  created() {
    this.$_log(
      "BaseSelectDB.vue] get data according to the received params...:" +
        this.srcTabName
    );

    this.getDictionaryTable(this.srcTabName)
      .then((r) => {
        this.$_log("DropDownList created response:", r.data);

        this.tableName = this.srcTabName;
        this.tableContent = r.data;
        this.$_log(
          "DropDownList created this.tableContent.length:" +
            this.tableContent.length
        );
        this.selectedItem = this.selected;
        this.dataReady = true;
      })
      .catch((error) => {
        this.$_log(error);
      });
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
    ...mapGetters("dbToolsModule", ["dictionary"]),
  },
  methods: {
    onChange(event) {
      this.$_log(event.target.name + "" + event.target.value);

      this.$emit("selectUpdated", {
        name: event.target.name,
        value: event.target.value,
      });
    },
    ...mapActions("dbToolsModule", ["getDictionaryTable"]),
  },
};
</script>
