import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiUser = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  registerTestUser(data) {
    return apiUser.post("/pub/rmu/", data);
  },
  login(userCredentials) {
    return apiUser.post("/admin/login/", userCredentials);
  },

  // --
  loginUsingIdProviderData(data) {
    try {
      return apiUser.post("/admin/luidp/", data);
    } catch (error) {
      vueApp.$_log(error);
      return -1;
    }
  },

  logout(refreshToken) {
    return apiUser.delete("/admin/logout/" + refreshToken);
  },

  async AA_refreshUserToken(rt) {
    try {
      const { data: response } = await apiUser.post("/pub/refreshToken", {
        refreshToken: rt,
      }); //use data destructuring to get data from the promise object
      return response;
    } catch (error) {
      vueApp.$_log(error);
      return -1;
    }
  },
  refreshUserToken(rt) {
    return apiUser.post("/pub/refreshToken/", { refreshToken: rt });
  },

  /* ========= REQUIRE AUTHENTICATION ===========> */
  createUser(uJ) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.post("/admin/cu/", uJ, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  getUsers(uJson) {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.post("/admin/gul/", uJson, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // generic sql search not in use now .... -->
  getUserList(nrElOnPage, pageNr, orderBy, name, remarks) {
    var qJson = {
      tableName: "User",
      pageNr: pageNr,
      nrElOnPage: nrElOnPage,
      orderBy: orderBy,
      name: name,
      remarks: remarks,
    };

    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.post("/admin/gqd/", qJson, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // generic sql search --<

  getUserDetails(uId) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.get("/admin/gu/" + uId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  setUserDetails(userJson) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.put("/admin/uu/", userJson, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  resetPasswordRequest(email) {
    return apiUser.get("/pub/rpr/" + email);
  },
  resetPassword(prJson) {
    var d = { password: prJson.password };
    var userToken = prJson.token;
    return apiUser.put("/admin/scup/", d, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  deleteUser(id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.delete("/admin/du/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // ----------------------------------------------------------------------------------
  checkEmail(email) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.get("/admin/checkEmail/" + email, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // ----------------------------------------------------------------------------------
  getCognitoUsers() {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.get("/admin/getCognitoUsers/", {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
