<template>
  <tr>
    <template v-for="(r, r_index) in tableFields" :key="r.id + '_' + r_index">
      <th v-if="!this.hideField(r.name)" :class="fieldClassName(r.name)">
        {{ fieldTranslation(r.name) }}
      </th>
    </template>

    <th>{{ fieldTranslation("functions") }}</th>
  </tr>
</template>

<script>
export default {
  name: "tabHeader",
  components: {},
  props: [
    "tableFields",
    "fieldsToHide",
    "fieldsClass",
    "funColName",
    "fieldsTranslation",
  ],
  created() {},
  methods: {
    hideField(fieldName) {
      let x = 0;
      if (Array.isArray(this.fieldsToHide)) {
        if (this.fieldsToHide.length > 0) {
          x = 1;
        }
      }
      if (x == 1) {
        for (let i = 0; i < this.fieldsToHide.length; i++) {
          if (this.fieldsToHide[i].name == fieldName) return true;
        }
      } else {
        return false;
      }
    },
    fieldTranslation(fieldName) {
      let r = "";
      let x = 0;
      if (Array.isArray(this.fieldsTranslation)) {
        if (this.fieldsTranslation.length > 0) {
          x = 1;
        }
      }
      if (x == 1) {
        for (let i = 0; i < this.fieldsTranslation.length; i++) {
          if (this.fieldsTranslation[i].name == fieldName) {
            console.log(this.fieldsTranslation[i]);
            r = this.fieldsTranslation[i].value;
          }
        }
      }

      if (r == "" && fieldName == "functions") {
        r = "functions!";
      }
      if (r == "" && fieldName == "id") {
        r = "#";
      }

      if (r != "") {
        return r;
      } else {
        return fieldName;
      }
    },
    fieldClassName(fieldName) {
      if (Array.isArray(this.fieldsClass)) {
        for (let i = 0; i < this.fieldsClass.length; i++) {
          if (this.fieldsClass[i].name == fieldName) {
            return this.fieldsClass[i].class;
          }
        }
        return "";
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
