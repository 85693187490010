<template>
  <div class="lgn_c0">
    <UserRegistration msg="Register new test account" />
  </div>
</template>

<script>
import UserRegistration from "@/components/dialogs/UserRegistration.vue";

export default {
  name: "RegisterUser",
  components: {
    UserRegistration,
  },
};
</script>

<style scoped>
.lgn_c0 {
  position: relative;
  text-align: center;
}
</style>
