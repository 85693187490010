import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiUserType = axios.create({
  baseURL: config.api_host,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  //
  getUserTypeList(jsonData) {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];
    return apiUserType.post("/admin/gutl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  getUserType(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserType.get("/admin/gut/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  createUserType(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserType.post("/admin/cut/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  updateUserType(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserType.put("/admin/uut/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  deleteUserType(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserType.delete("/admin/dut/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
