<template>
  <div class="dlg_c_0">
    <ResetPasswordRequestDialog msg="Reset password" />
  </div>
</template>

<script>
import ResetPasswordRequestDialog from "@/components/dialogs/ResetPasswordRequest.vue";

export default {
  name: "PasswordResetRequest",
  components: {
    ResetPasswordRequestDialog,
  },
};
</script>

<style scoped>
.dlg_c_0 {
  position: relative;
  text-align: center;
}
</style>
