import { createRouter, createWebHistory } from "vue-router";
import GStore from "@/store";
//import { mapGetters, mapActions } from "vuex";
import { vueApp } from "@/main.js";
import NProgress from "nprogress";

// ==================================================================
// ==================================================================
// ==================================================================

//import MainTemplate from "@/App.vue";
import HomeView from "@/views/public/HomeView.vue";

import UserDashboard from "@/views/protected/UserDashboard.vue";

import LoginView from "@/views/protected/LoginView.vue";
import UserRegistration from "@/views/protected/RegisterUserView.vue";
import ResetPasswordRequest from "@/views/protected/authorization/ResetPasswordRequestView.vue";
import ResetPassword from "@/views/protected/authorization/ResetPasswordView.vue";

// -->
import UserDetails from "@/views/protected/UserDetails.vue"; // << current loggedin user!!

import UserView from "@/views/protected/user/ListView.vue";
import User from "@/views/protected/user/LayoutView.vue"; // not used at the moment
import UserAdd from "@/views/protected/user/AddDlg.vue";
import UserEdit from "@/views/protected/user/EditDlg.vue";

import UserTypes from "@/views/protected/userType/ListView.vue";
import UserType from "@/views/protected/userType/LayoutView.vue";
import UserTypeAdd from "@/views/protected/userType/AddDlg.vue";
import UserTypeEdit from "@/views/protected/userType/EditDlg.vue";

import UserStatuses from "@/views/protected/userStatus/ListView.vue";
import UserStatus from "@/views/protected/userStatus/LayoutView.vue";
import UserStatusAdd from "@/views/protected/userStatus/AddDlg.vue";
import UserStatusEdit from "@/views/protected/userStatus/EditDlg.vue";

import AppModules from "@/views/protected/applicationModule/ListView.vue";
import AppModule from "@/views/protected/applicationModule/LayoutView.vue";
import AppModuleAdd from "@/views/protected/applicationModule/AddDlg.vue";
import AppModuleEdit from "@/views/protected/applicationModule/EditDlg.vue";

import AppModuleOperations from "@/views/protected/applicationModuleOperation/ListView.vue";
import AppModuleOperation from "@/views/protected/applicationModuleOperation/LayoutView.vue";
import AppModuleOperationAdd from "@/views/protected/applicationModuleOperation/AddDlg.vue";
import AppModuleOperationEdit from "@/views/protected/applicationModuleOperation/EditDlg.vue";

import UserTypeOperations from "@/views/protected/userTypeOperation/ListView.vue";
import UserTypeOperation from "@/views/protected/userTypeOperation/LayoutView.vue";
import UserTypeOperationAdd from "@/views/protected/userTypeOperation/AddDlg.vue";
import UserTypeOperationEdit from "@/views/protected/userTypeOperation/EditDlg.vue";
// <--
import loginIdP from "@/views/loginIdP.vue";
import ErrorDisplay from "@/views/ErrorDisplay.vue";

// =============================================================================
// =============================================================================
// =============================================================================
const routes = [
  // =============================================================
  // public routes =>
  {
    path: "/",
    name: "home",
    component: HomeView,
  },

  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/public/AboutView.vue"),
  },
  {
    path: "/facts",
    name: "facts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/public/FactsView.vue"),
  },
  {
    path: "/survey",
    name: "survey",
    //component: Survey,
    component: () =>
      import(
        /* webpackChunkName: "Survey" */ "@/views/public/TestSurvey01.vue"
      ),
  },
  {
    path: "/login",
    name: "login",
    //props: true,
    component: LoginView,
    meta: { internalViewWithoutLogin: true },
  },
  //ASW 20232-05-25 =>
  {
    path: "/login/:provider/callback",
    name: "loginIdP",
    props: true,
    meta: { idp: true, requireAuth: false },
    component: loginIdP,
    /*{
      template: '<div class="auth-component">{{{provider}}</div>',
    },
	*/
  },
  //ASW 20232-05-25 =<
  {
    path: "/register",
    name: "register",
    component: UserRegistration,
    meta: { internalViewWithoutLogin: true },
  },
  {
    path: "/rpr",
    name: "resetPasswordRequest",
    component: ResetPasswordRequest,
    meta: { internalViewWithoutLogin: true },
  },
  {
    path: "/rp/:token",
    name: "resetPassword",
    component: ResetPassword,
    props: true,
    meta: { internalViewWithoutLogin: true },
  },
  {
    path: "/rp/:token",
    name: "resetPassword_UserLogedIn",
    component: ResetPassword,
    props: true,
  },
  // public routes <=
  // =============================================================

  // =============================================================
  // P R O T E C T E D   R O U T E S >>>
  {
    path: "/userdashboard/",
    name: "UserDashboard",
    component: UserDashboard,

    props: {
      id: GStore.state.userModule.currentLoggedUser.user_id,
    },

    meta: { requireAuth: true },
  },

  // profile menu >>>
  // current loggedin user ->
  {
    path: "/userDetails/:id",
    name: "userDetails",
    component: UserDetails,
    props: true,
    meta: { requireAuth: true },
  },
  // <-
  // profile menu <<<

  // main admin menu =>
  // clients >>>
  {
    path: "/clients/:pageNr",
    //path: "/clients/:pageNr/:id",
    //path: "/clients/:pageNr/:id/:clientName/:remarks/:country_id", // -1 means param undefined
    name: "clientsView",
    component: () =>
      import(
        /* webpackChunkName: "ClientView" */ "@/views/protected/client/ListView.vue"
      ),
    //props: true,

    props: (route) => {
      return {
        pageNr: parseInt(route.params.pageNr) || 0,
        //id: parseInt(route.params.id) || 0,
        //clientName: 0, //route.params.clientName != 0 ? route.params.clientName : 0,
        //remarks: route.params.remarks, //route.params.remarks != 0 ? route.params.remarks : 0,
        //country_id: parseInt(route.params.country_id) || 0,
      };
    },

    meta: { requireAuth: true },
  },
  {
    path: "/clientadd",
    name: "clientAdd",
    component: () =>
      import(
        /* webpackChunkName: "ClientAdd" */ "@/views/protected/client/AddDlg.vue"
      ),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/client/:id",
    name: "clientLayout",
    component: () =>
      import(
        /* webpackChunkName: "ClientLayout" */ "@/views/protected/client/Layout.vue"
      ),
    props: true,
    //props: (route) => ({ id: parseInt(route.params.id) || 0 }),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "clientEdit",
        component: () =>
          import(
            /* webpackChunkName: "ClientEdit" */ "@/views/protected/client/EditDlg.vue"
          ),
      },
    ],
  },
  // clients <<<

  // locations >>>
  {
    path: "/locations/:pageNr/:ClientId",
    name: "locationsView",
    component: () =>
      import(
        /* webpackChunkName: "LocationView" */ "@/views/protected/location/ListView.vue"
      ),
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
      ClientId: parseInt(route.params.ClientId) || 0,
    }),
    meta: { requireAuth: true },
  },
  {
    path: "/locationadd",
    name: "locationAdd",
    component: () =>
      import(
        /* webpackChunkName: "LocationAdd" */ "@/views/protected/location/AddDlg.vue"
      ),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/location/:id/:ClientId",
    name: "locationLayout",
    component: () =>
      import(
        /* webpackChunkName: "LocationLayout" */ "@/views/protected/location/Layout.vue"
      ),
    props: true,
    //props: (route) => ({ id: parseInt(route.params.id) || 0, ClientId: parseInt(route.params.ClientId)||0  }),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "locationEdit",
        component: () =>
          import(
            /* webpackChunkName: "LocationEdit" */ "@/views/protected/location/EditDlg.vue"
          ),
      },
    ],
  },
  // locations <<<

  // zone >>>
  {
    path: "/zones/:pageNr/:ClientId/:LocationId",
    name: "zonesView",
    //component: ZoneView,
    component: () =>
      import(
        /* webpackChunkName: "ZoneView" */ "@/views/protected/zone/ListView.vue"
      ),
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
      ClientId: parseInt(route.params.ClientId) || 0,
      LocationId: parseInt(route.params.LocationId) || 0,
    }),
    meta: { requireAuth: true },
  },
  {
    path: "/zoneadd",
    name: "zoneAdd",
    //component: ZoneAdd,
    component: () =>
      import(
        /* webpackChunkName: "ZoneAdd" */ "@/views/protected/zone/AddDlg.vue"
      ),
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/zone/:id",
    name: "zoneLayout",
    //component: ZoneLayout,
    component: () =>
      import(
        /* webpackChunkName: "ZoneLayout" */ "@/views/protected/zone/Layout.vue"
      ),
    props: true,
    //props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "zoneEdit",
        //component: ZoneEdit,
        component: () =>
          import(
            /* webpackChunkName: "ZoneEdit" */ "@/views/protected/zone/EditDlg.vue"
          ),
      },
    ],
  },
  // zone <<<

  // stream >>>
  {
    path: "/streams/:pageNr/:ClientId",
    name: "streamsView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
      ClientId: parseInt(route.params.ClientId) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "StreamsView" */ "@/views/protected/stream/ListView.vue"
      ),
    meta: { requireAuth: true },
  },

  {
    path: "/stream/add",
    name: "streamAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "StreamAdd" */ "@/views/protected/stream/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/stream/:id",
    name: "stream",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Stream" */ "@/views/protected/stream/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "streamEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "StreamEdit" */ "@/views/protected/stream/EditDlg.vue"
          ),
      },
    ],
  },
  // stream <<<
  // main admin menu <=
  // =============================================================

  // =============================================================
  // config menu =>
  // users >>>
  {
    path: "/user/:pageNr",
    name: "userView",
    component: UserView,
    //props: true,
    props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
  },
  {
    path: "/useradd",
    name: "userAdd",
    component: UserAdd,
    props: true,
    //props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
  },
  {
    path: "/user/:id",
    name: "user",
    component: User,
    props: true,
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "userEdit",
        component: UserEdit,
      },
    ],
  },
  // users <<<

  // usertypes >>
  {
    path: "/usertypes/:pageNr",
    name: "userTypes",
    component: UserTypes,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/utadd",
    name: "userTypeAdd",
    component: UserTypeAdd,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/usertype/:id",
    name: "userType",
    component: UserType,
    props: true,
    //props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "userTypeEdit",
        component: UserTypeEdit,
      },
    ],
  },
  // usertypes <<

  // userstatus >>
  {
    path: "/userstatuses/:pageNr",
    name: "userStatuses",
    component: UserStatuses,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/usadd",
    name: "userStatusAdd",
    component: UserStatusAdd,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/userstatus/:id",
    name: "userStatus",
    component: UserStatus,
    props: true,
    //props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "userStatusEdit",
        component: UserStatusEdit,
      },
    ],
  },
  // userstatus <<
  // AM =>
  {
    path: "/appmodules/:pageNr",
    name: "appModules",
    component: AppModules,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/amadd",
    name: "appModuleAdd",
    component: AppModuleAdd,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/appmodule/:id",
    name: "appModule",
    component: AppModule,
    props: true,
    //props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "appModuleEdit",
        component: AppModuleEdit,
      },
    ],
  },
  // AM <=

  // AMO =>
  {
    path: "/appmoduleoperations/:pageNr",
    name: "appModuleOperationView",
    component: AppModuleOperations,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/amoadd",
    name: "appModuleOperationAdd",
    component: AppModuleOperationAdd,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/appmoduleoperation/:id",
    name: "appModuleOperation",
    component: AppModuleOperation,
    props: true,
    //props: (route) => ({ pageNr: parseInt(route.params.pageNr) || 0 }),
    meta: { requireAuth: true },
    children: [
      /*
      {
        path: "add",
        name: "appModuleOperationAdd",
        component: AppModuleOperationAdd,
      },
	  */
      {
        path: "edit",
        name: "appModuleOperationEdit",
        component: AppModuleOperationEdit,
      },
    ],
  },
  // AMO =<

  // UTO =>
  {
    path: "/usertypeoperations/:pageNr",
    name: "userTypeOperationView",
    component: UserTypeOperations,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/utoadd",
    name: "userTypeOperationAdd",
    component: UserTypeOperationAdd,
    props: true,
    meta: { requireAuth: true },
  },
  {
    path: "/userrtypeoperation/:id",
    name: "UserTypeOperation",
    component: UserTypeOperation,
    props: true,
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "userTypeOperationEdit",
        component: UserTypeOperationEdit,
      },
    ],
  },
  // UTO =<

  // +++++++++++++++
  //translations==>
  //language >
  {
    path: "/lng/:pageNr",
    name: "lngView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "LanguageView" */ "@/views/protected/translation/language/ListView.vue"
      ),
    meta: { requireAuth: true },
  },

  {
    path: "/lng/add",
    name: "lngAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "LanguageAdd" */ "@/views/protected/translation/language/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/lng/:id",
    name: "lng",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "Language" */ "@/views/protected/translation/language/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "lngEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "LanguageEdit" */ "@/views/protected/translation/language/EditDlg.vue"
          ),
      },
    ],
  },
  //ModuleSection
  //ModuleElement >
  {
    path: "/mde/:pageNr",
    name: "ModuleElementView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "ModuleElementView" */ "@/views/protected/translation/ModuleElement/ListView.vue"
      ),
    meta: { requireAuth: true },
  },

  {
    path: "/mde/add",
    name: "ModuleElementAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ModuleElementAdd" */ "@/views/protected/translation/ModuleElement/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mde/:id",
    name: "ModuleElement",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ModuleElement" */ "@/views/protected/translation/ModuleElement/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "ModuleElementEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ModuleElementEdit" */ "@/views/protected/translation/ModuleElement/EditDlg.vue"
          ),
      },
    ],
  },
  //ModuleElementTranslation
  {
    path: "/mdet/:pageNr",
    name: "ModuleElementTranslationView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "ModuleElementTranslationView" */ "@/views/protected/translation/ModuleElementTranslation/ListView.vue"
      ),
    meta: { requireAuth: true },
  },

  {
    path: "/mdet/add",
    name: "ModuleElementTranslationAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ModuleElementTranslationAdd" */ "@/views/protected/translation/ModuleElementTranslation/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mdet/:id",
    name: "ModuleElementTranslation",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "ModuleElementTranslation" */ "@/views/protected/translation/ModuleElementTranslation/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "ModuleElementTranslationEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "ModuleElementTranslationEdit" */ "@/views/protected/translation/ModuleElementTranslation/EditDlg.vue"
          ),
      },
    ],
  },
  //translations==<
  // +++++++++++++++

  // +++++++++++++++
  //music tags ==>
  //MmpTags
  {
    path: "/mmpt/:pageNr",
    name: "MmpTagsView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "MmpTagsView" */ "@/views/protected/musicTags/Tags/ListView.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mmpt/add",
    name: "MmpTagsAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTagsAdd" */ "@/views/protected/musicTags/Tags/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mmpt/:id",
    name: "MmpTags",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTags" */ "@/views/protected/musicTags/Tags/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "MmpTagsEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MmpTagsEdit" */ "@/views/protected/musicTags/Tags/EditDlg.vue"
          ),
      },
    ],
  },

  //MmpTagCat
  {
    path: "/mmptc/:pageNr",
    name: "MmpTagCatView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "MmpTagCatView" */ "@/views/protected/musicTags/TagCategoires/ListView.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mmptc/add",
    name: "MmpTagCatAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTagCatAdd" */ "@/views/protected/musicTags/TagCategoires/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mmptc/:id",
    name: "MmpTagCat",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTagCat" */ "@/views/protected/musicTags/TagCategoires/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "MmpTagCatEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MmpTagCatEdit" */ "@/views/protected/musicTags/TagCategoires/EditDlg.vue"
          ),
      },
    ],
  },

  //MmpTagRel
  {
    path: "/mmptr/:pageNr",
    name: "MmpTagRelView",
    //props: true,
    props: (route) => ({
      pageNr: parseInt(route.params.pageNr) || 0,
    }),
    component: () =>
      import(
        /* webpackChunkName: "MmpTagRelView" */ "@/views/protected/musicTags/TagRelations/ListView.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mmptr/add",
    name: "MmpTagRelAdd",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTagRelAdd" */ "@/views/protected/musicTags/TagRelations/AddDlg.vue"
      ),
    meta: { requireAuth: true },
  },
  {
    path: "/mmptr/:id",
    name: "MmpTagRel",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTagRel" */ "@/views/protected/musicTags/TagRelations/Layout.vue"
      ),
    meta: { requireAuth: true },
    children: [
      {
        path: "edit",
        name: "MmpTagRelEdit",
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "MmpTagRelEdit" */ "@/views/protected/musicTags/TagRelations/EditDlg.vue"
          ),
      },
    ],
  },

  {
    path: "/mmptrblk/:TagCatId",
    name: "MmpTagRelBlkView",
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "MmpTagRel" */ "@/views/protected/musicTags/TagRelations/BulkUpdateView.vue"
      ),
    meta: { requireAuth: true },
  },
  //music tags ==<
  // +++++++++++++++

  // config menu =<

  // =============================================================================

  {
    path: "/error/:error",
    name: "ErrorDisplay",
    props: true,
    component: ErrorDisplay,
  },

  /* [404] anything else... */
  /*{ path: "/:catchAll(.*)", component: pageNotFoundPage },*/
  /*{ path: "/:catchAll(.*)", redirect: { name: "UserDashboard" } },*/
  { path: "/:catchAll(.*)", redirect: { name: "home" } },
];

// ===========================================================================
// ===========================================================================
// ===========================================================================

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach(async (to, from, next) => {
  NProgress.start();

  vueApp.$_log(
    "router beforeEach to:" +
      to.fullPath +
      " isTokenValid:" +
      GStore.getters["userModule/isTokenValid"] +
      " expires:" +
      GStore.state.userModule.currentLoggedUser.expires
  );

  console.log(GStore.state.userModule.checkingState);
  if (to.meta.idp == true) {
    next();
  } else {
    if (GStore.state.userModule.checkingState == 0) {
      await GStore.dispatch("userModule/isUserLoggedIn_Action_P").then(
        (response) => {
          vueApp.$_log("router beforeEach response:" + response);

          if (response === false && to.meta.requireAuth) {
            const n = {
              type: "error",
              message: "Sorry you are not authorized",
            };
            GStore.dispatch("notificationModule/add", n, { root: true });
          }

          if (typeof from.href != "undefined") {
            // <--- If this navigation came from a previous page.
            vueApp.$_log("router beforeEach from.href IS defined:" + from.href);
            //return false;
            next();
          } else {
            // <--- if navigating directly
            vueApp.$_log("router beforeEach asw from.href is not defined! ");
            //return { path: "/" }; // <--- Push navigation to the root route.
            next();
          }
        },
        async (error) => {
          vueApp.$_log(
            "router beforeEach000	 error:",
            error.response.data.error
          );
          if (error.response.data.error == "not valid token") {
            vueApp.$_log(" ooook so lets logout and login again...");

            await GStore.dispatch("userModule/logout")
              .then(() => {
                vueApp.$_log(
                  " in router beforeEach just after logout .... current to:",
                  to
                );
                //return { name: "login" };

                vueApp.$_log(" ooook so lets reddirect route.... to: home");
                vueApp.$_log("===============================================");
                //next({ name: "home" });
                next({ name: "login" });
              })
              .catch((error) => {
                vueApp.$_log(" dammit ... error:", error);
              });
          }
        }
      );

      //
    } else {
      console.log("somebody already cheking user status....");
      next();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
