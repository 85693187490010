import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiGeoLoc = axios.create({
  baseURL: config.api_host,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  getUserPositionByIp() {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];

    return apiGeoLoc.get("/pub/gl_gp/", {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
