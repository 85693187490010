import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiLocation = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getLocationList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiLocation.post("/admin/glocl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getLocation(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiLocation.get("/admin/gloc/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createLocation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiLocation.post("/admin/cloc/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateLocation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiLocation.put("/admin/uloc/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteLocation(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiLocation.delete("/admin/dloc/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
