<template>
  <template v-if="dataReady">
    <router-view :id="id" />
  </template>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AppModuleOperationLayout",
  props: ["id"],

  data() {
    return {
      AppModuleOperation: null,
      dataReady: false,
    };
  },
  created() {
    this.$_log("Just before getAppModuleOperationLayout.... amoId:" + this.id);
    this.dataReady = true;
  },
  methods: {
    ...mapActions("appModOp", ["getAppModuleOperation"]),
  },
};
</script>
<style scoped></style>
