<template>
  <div v-if="dataReady" class="userStatusViewContainer">
    <listHeader
      pLabel="UserStatus"
      pLinkName="userStatusAdd"
      dataSrc="userStatus"
      :searchVars="searchVariables"
      @searchValues="Search"
    />
    <div class="tabDataContainer">
      <table>
        <!--
		<tr>
          <th>lp.</th>
          <th>user status</th>
          <th class="wideTabColumn">remarks</th>
          <th>functions</th>
        </tr>
		-->

        <tabHeader
          :tableFields="tabFields"
          :fieldsToHide="tabFieldsToHide"
          :fieldsClass="tabFieldsClass"
          :fieldsTranslation="tabFieldsTranslations"
        />

        <tr
          v-for="(r, r_index) in tableContent"
          :key="r.id"
          :class="computedCssClass(r_index)"
        >
          <td>{{ r_index }}</td>
          <td>{{ r.name }}</td>
          <td>{{ r.remarks }}</td>

          <td>
            <router-link :to="{ name: 'userStatusEdit', params: { id: r.id } }"
              >edit</router-link
            >
            /
            <a
              @click="deleteElement($event, r.id, r.name)"
              style="cursor: pointer"
            >
              delete
            </a>
          </td>
        </tr>
      </table>
    </div>

    <pagination
      :pageNr="pageNr"
      :totalPages="totalPages"
      :nrRowsTotal="nrRowsTotal"
      linkName="userStatuses"
      :nrEOP="nrElOnPage"
    />
  </div>
</template>

<script>
import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";

import { mapGetters, mapActions } from "vuex";
import { watchEffect } from "vue";
import userStatusSrv from "@/services/userStatusService.js"; // access to the APIs
import pagination from "@/components/listView/tabPagination.vue";
import listHeader from "@/components/listView/listHeader.vue";
import tabHeader from "@/components/listView/tabHeader.vue";

export default {
  name: "UserStatusesList",
  components: {
    listHeader,
    pagination,
    tabHeader,
  },
  props: ["id", "msg", "pageNr"],
  data() {
    return {
      users: null,

      usertype: null,
      tableName: "userStatus",
      tableContent: null,

      tabFields: null,
      tabFieldsToHide: [],
      tabFieldsClass: [],
      tabFieldsTranslations: [],

      dataReady: false,

      // --> fields to be search
      searchVariables: {
        name: {
          label: "name",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        remarks: {
          label: "remarks",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
      },
      // --< fields to be search
      nrRowsTotal: 0,
      nrElOnPage: 10,
      orderBy: "id",
    };
  },
  created() {
    this.$_log("pageNr:" + this.pageNr);
    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.nrRowsTotal / this.nrElOnPage);
    },
    ...mapGetters("userModule", ["userToken", "isUserLoggedIn"]),
  },

  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("userStatus", ["getUserStatusList"]),
    deleteElement(event, elemId, elemName) {
      ///

      useConfirmBeforeAction(
        function () {
          //alert("action confirmed!");
          userStatusSrv
            .deleteUserStatus(elemId)
            .then((response) => {
              this.$_log("asw response.data:", response.data);

              var n = {
                type: "success",
                message:
                  "You just correctly removed user status '" + elemName + "'.",
              };
              this.addNotificationMessage(n).catch((err) => {
                this.$_log("addNotificationMessage 00 error:", err);
              });
            })
            .then(() => {
              this.$_log(
                "AAAAA this.$router.currentRoute:",
                this.$router.currentRoute
              );
              this.getData();
              /*
              var i = 0;
              this.$router
                .push({
                  name: "userStatuses",
                  params: { pageNr: i },
                })
                .then(() => {
                  this.$_log("A00000000000000000000000");
                })
                .catch((err) => {
                  this.$_log("A err:", err);
                });
				*/
            })
            .catch((error) => {
              this.$_log("asw deleteStatus error:", error);
            });
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        function () {
          this.$_log("action canceled ...");
        },
        {
          // message to be displayed
          question:
            "Do you realy wnat to delete user status `" + elemName + "` ?",
        }
      );
      ///
    },
    Search(data) {
      this.$_log("Search data:", data);
      this.searchVariables = data;
      this.getData();
    },
    getData() {
      this.$_log("Just before getUserStatusList....getData()...");
      var jo = {
        // search params ->
        name: this.searchVariables.name.value,
        remarks: this.searchVariables.remarks.value,
        // search params <-

        pageNr: this.pageNr,
        nrElOnPage: this.nrElOnPage,
        orderBy: this.orderBy,
      };

      this.getUserStatusList(jo)
        .then((r) => {
          this.$_log("Just before getUserStatusList....");
          this.$_log("created response:", r);

          this.nrRowsTotal = r.nrRowsTotal;
          this.tableContent = r.data;

          this.tabFields = r.fields;
          //this.tabFieldsToHide.push({ name: "remarks" });
          this.tabFieldsClass.push({ name: "remarks", class: "wideTabColumn" });
          this.tabFieldsTranslations.push({
            name: "functions",
            value: "FUNKCJE",
          });

          this.$_log(
            "created[" +
              this.tableName +
              "] this.tableContent.length:" +
              this.tableContent.length +
              " nrRowsTotal:" +
              this.nrRowsTotal
          );

          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    computedCssClass(i) {
      if (i % 2 === 0) {
        return "";
      } else {
        return "odd";
      }
    },
  },
};
</script>

<style scoped></style>
