export default class PageModule {
  constructor() {
    this.state = {
      currentContext: {
        orderBy: "", // TODO: <<<
        searchParams: "", //TODO: <<<
        listColumns: "", //TODO: <<<
        pageNr: 0,
        id: 0, // fe what user was just edited etc...
        name: "",
      },
    };
    this.getters = {
      /// >>
      getCurrentId(state) {
        return state.currentContext.id;
      },
      getCurrentName(state) {
        return state.currentContext.name;
      },
      getCurrentPage(state) {
        return state.currentContext.pageNr;
      },
      getCurrentOrderBy(state) {
        return state.currentContext.orderBy;
      },
      getCurrentSearchParams(state) {
        return state.currentContext.searchParams;
      },
      getCurrentListColumns(state) {
        return state.currentContext.listColumns;
      },
      /// <<
    };
    this.actions = {
      // <<<
      setCurrentId({ commit }, usrId) {
        commit("SET_CONTEXT_ID", usrId);
      },
      setCurrentName({ commit }, name) {
        commit("SET_CONTEXT_NAME", name);
      },
      setCurrentPage({ commit }, nrPage) {
        commit("SET_CONTEXT_PAGENR", nrPage);
      },
      setCurrentOrderBy({ commit }, orderBy) {
        commit("SET_CONTEXT_ORDERBY", orderBy);
      },
      setCurrentSearchParams({ commit }, searchParams) {
        commit("SET_CONTEXT_SEARCHPARAMS", searchParams);
      },
      setCurrentListColumns({ commit }, listColumns) {
        commit("SET_CONTEXT_LISTCOLUMNS", listColumns);
      },
      // <<<
    };
    this.mutations = {
      // >>>
      SET_CONTEXT_ID(state, usrId) {
        state.currentContext.id = usrId;
      },
      SET_CONTEXT_NAME(state, name) {
        state.currentContext.name = name;
      },
      SET_CONTEXT_PAGENR(state, pageNr) {
        state.currentContext.pageNr = pageNr;
      },
      SET_CONTEXT_ORDERBY(state, orderBy) {
        state.currentContext.orderBy = orderBy;
      },
      SET_CONTEXT_SEARCHPARAMS(state, searchParams) {
        state.currentContext.searchParams = searchParams;
      },
      SET_CONTEXT_LISTCOLUMNS(state, listColumns) {
        state.currentContext.listColumns = listColumns;
      },
      // <<<
    };
  }
}
