<template>
  <div v-if="dataReady" class="applicationModuleOperationViewContainer">
    <listHeader
      pLabel="ApplicationModule_Operation"
      pLinkName="appModuleOperationAdd"
      dataSrc="ApplicationModule_Operation"
      :searchVars="searchVariables"
      @searchValues="Search"
    />
    <div class="tabDataContainer">
      <table>
        <!--
        <tr>
          <th>lp.</th>

          <th class="wideTabColumn">remarks</th>
          <th>app module</th>
          <th>operation</th>
          <th>url</th>
          <th>method</th>
          <th class="wideTabColumn">parms</th>
          <th>functions</th>
        </tr>
		-->

        <tabHeader
          :tableFields="tabFields"
          :fieldsToHide="tabFieldsToHide"
          :fieldsClass="tabFieldsClass"
          :fieldsTranslation="tabFieldsTranslations"
        />

        <tr
          v-for="(r, r_index) in tableContent"
          :key="r.id"
          :class="computedCssClass(r_index)"
        >
          <td>{{ r_index }}</td>

          <td>{{ r.remarks }}</td>

          <td>{{ r.am_name }}</td>
          <td>{{ r.o_name }}</td>
          <td>{{ r.url }}</td>
          <td>{{ r.method }}</td>
          <td>{{ r.params }}</td>

          <td>
            <router-link
              :to="{ name: 'appModuleOperationEdit', params: { id: r.id } }"
              >edit</router-link
            >
            /
            <a @click="deleteElement($event, r.id)" style="cursor: pointer">
              delete
            </a>
          </td>
        </tr>
      </table>
    </div>

    <pagination
      :pageNr="pageNr"
      :totalPages="totalPages"
      :nrRowsTotal="nrRowsTotal"
      linkName="appModuleOperationView"
      :nrEOP="nrElOnPage"
    />
  </div>
</template>

<script>
import { watchEffect } from "vue";
import { mapGetters, mapActions } from "vuex";

import amoService from "@/services/appModuleOperationService.js";
import pagination from "@/components/listView/tabPagination.vue";
import listHeader from "@/components/listView/listHeader.vue";

import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";
import tabHeader from "@/components/listView/tabHeader.vue";

export default {
  name: "ApplicationModuleOperation",

  props: ["msg", "pageNr"],
  components: {
    pagination,
    listHeader,
    tabHeader,
  },
  data() {
    return {
      usertype: null,
      tableName: "ApplicationModule_Operation",
      tableContent: null,

      tabFields: null,
      tabFieldsToHide: [],
      tabFieldsClass: [],
      tabFieldsTranslations: [],

      dataReady: false,

      // --> fields to be search
      searchVariables: {
        remarks: {
          label: "remarks",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        url: {
          label: "url",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        ApplicationModule_id: {
          label: "app module",
          value: null,
          type: "fk_int",
          dataSrc: "ApplicationModule",
          showInSearchBar: true,
          width: 10,
        },
        method: {
          label: "method",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        params: {
          label: "params",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
        Operation_id: {
          label: "operation",
          value: null,
          type: "text",
          showInSearchBar: false,
          width: 10,
        },
      },
      // --< fields to be search

      nrRowsTotal: 0,
      nrElOnPage: 10,
      orderBy: "amo.id",
    };
  },
  created() {
    //this.$_log("TOKEN >>>>" + this.$store.state.userModule.currentLoggedUser.token);
    this.$_log("TOKEN >>>>" + this.userToken);
    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.nrRowsTotal / this.nrElOnPage);
    },
    ...mapGetters("userModule", ["userToken", "isUserLoggedIn"]),
  },
  methods: {
    callDeleteElem(elemId) {
      amoService
        .deleteAppModuleOperation(elemId)
        .then((response) => {
          this.$_log("asw response.data:", response.data);

          var n = {
            type: "success",
            message:
              "You just correctly removed module operation. (id:" +
              response.data.removedId +
              ")",
          };
          this.addNotificationMessage(n);
        })
        .then(() => {
          this.getData();
          /*
          this.$router.push({
            name: "appModuleOperationView",
            params: { pageNr: 0 },
          });
		  */
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
    deleteElement(event, elemId) {
      this.$_log("event:" + event);
      this.$_log("delete elem:" + elemId);

      useConfirmBeforeAction(
        function () {
          this.callDeleteElem(elemId);
        },
        function () {
          this.$_log("action canceled ... ");
        },
        {
          question:
            "Do you realy want to remove Application Module Operation '" +
            elemId +
            "' ?",
        }
      );
    },
    Search(data) {
      this.$_log("Search data:", data);
      this.searchVariables = data;
      this.getData();
    },
    computedCssClass(i) {
      if (i % 2 === 0) {
        return "";
      } else {
        return "odd";
      }
    },
    getData() {
      var jo = {
        // search params ->
        remarks: this.searchVariables.remarks.value,
        url: this.searchVariables.url.value,
        method: this.searchVariables.method.value,
        params: this.searchVariables.params.value,
        ApplicationModule_id: this.searchVariables.ApplicationModule_id.value,
        Operation_id: this.searchVariables.Operation_id.value,
        // search params <-
        pageNr: this.pageNr,
        nrElOnPage: this.nrElOnPage,
        orderBy: this.orderBy,
      };
      this.$_log("Just before getAppModuleOperationList....jo:", jo);
      this.getAppModuleOperationList(jo)
        .then((r) => {
          this.$_log("Just before getAppModuleOperationList....");
          this.$_log("created response:", r);

          this.nrRowsTotal = r.nrRowsTotal;
          this.tableContent = r.data;

          this.tabFields = r.fields;
          this.tabFieldsToHide.push(
            { name: "user_created" },
            { name: "time_created" },
            { name: "ApplicationModule_id" },
            { name: "Operation_id" }
          );
          this.tabFieldsClass.push(
            { name: "remarks", class: "wideTabColumn" },
            { name: "params", class: "wideTabColumn" }
          );
          this.tabFieldsTranslations.push({
            name: "functions",
            value: "functions",
          });

          this.$_log(
            "created[" +
              this.tableName +
              "] this.tableContent.length:" +
              this.tableContent.length
          );

          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    ...mapActions("dbToolsModule", ["getDictionaryTable"]),
    ...mapActions("appModOp", ["getAppModuleOperationList"]),
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
  },
};
</script>

<style scoped></style>
