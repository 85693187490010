<template>
  <div class="hello">
    <div class="bullet01"></div>
    <div>{{ msg }}</div>
    <h4>
      Musaic biz is a<br />
      new source of music
    </h4>
    <p>for your business and for your clients</p>
    <div class="tpl_nav">
      <router-link to="/register"
        ><div class="tryItBtn">try now</div></router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello {
  position: relative;

  top: 200px;
  left: 70px;
  width: 500px;

  text-align: left;
  /*font: normal normal bold 22px/32px Poppins;*/
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;

  text-shadow: 0px 0px 6px rgba(255, 255, 255, 0.8);
  /* ---- */
  /*border: rgb(36, 20, 90) 2px dotted;*/
}
.hello h4 {
  color: #1a1a1a;
  font: normal normal bold 42px/52px Poppins;
}
.hello p {
  color: #5c6670;
  font: normal normal bold 24px/28px Poppins;
}

.bullet01 {
  position: absolute;
  top: 55px;
  left: -15px;
  width: 30px;
  height: 30px;
  background: var(--unnamed-color-ced800) 0% 0% no-repeat padding-box;
  background: #ced800 0% 0% no-repeat padding-box;
  opacity: 1;
  border-radius: 100px;
  z-index: -100;
}

.tpl_nav {
  /*position: relative;*/
  display: inline-block;
  text-align: center;
}
.tryItBtn {
  /*position: absolute;*/

  border: 2px solid #68d061;
  padding: 4px;
  width: 120px;
  /*height: 30px;*/

  top: 50%;
  left: 50%;
  margin: 0px 0 0 0px;

  color: white;

  background: transparent
    linear-gradient(
      109deg,
      var(--unnamed-color-ced800) 0%,
      var(--unnamed-color-01c8c1) 100%
    )
    0% 0% no-repeat padding-box;
  background: transparent linear-gradient(109deg, #ced800 0%, #01c8c1 100%) 0%
    0% no-repeat padding-box;
  border-radius: 100px;
  box-shadow: none;
}

.tryItBtn:hover {
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  box-shadow: 2px 1000px 1px #fff inset;

  color: #68d061;
}
</style>
