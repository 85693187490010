import { createStore } from "vuex";

import * as notificationModule from "@/store/modules/generic/notifications.js";
import * as dbToolsModule from "@/store/modules/generic/dbTools.js";
import * as userModule from "@/store/modules/pages/users.js";

import * as clientModule from "@/store/modules/pages/clients.js";
import * as locationModule from "@/store/modules/pages/location.js";
import * as zoneModule from "@/store/modules/pages/zone.js";

import * as appModOp from "@/store/modules/pages/appModOp.js";
import * as appMod from "@/store/modules/pages/appMod.js";
import * as userType from "@/store/modules/pages/userType.js";
import * as userStatus from "@/store/modules/pages/userStatus.js";

import * as streamModule from "@/store/modules/pages/streams.js";

// --
import * as lngModule from "@/store/modules/pages/language.js";
import * as moduleSectionModule from "@/store/modules/pages/ModuleSection.js";
import * as moduleElementModule from "@/store/modules/pages/ModuleElement.js";
import * as moduleElementTranslationModule from "@/store/modules/pages/ModuleElementTranslation.js";
import * as musicTags from "@/store/modules/pages/musicTags.js";
import * as musicTagRelations from "@/store/modules/pages/musicTagRelations.js";
import * as musicTagCategories from "@/store/modules/pages/musicTagCategories.js";
// --

import tb from "@/features/toolBox.js";

export default createStore({
  modules: {
    notificationModule,
    dbToolsModule,
    userModule,

    clientModule,
    locationModule,
    zoneModule,
    streamModule,

    appModOp,
    appMod,
    userType,
    userStatus,

    // translations
    lngModule,
    moduleSectionModule,
    moduleElementModule,
    moduleElementTranslationModule,
    // music tags
    musicTags,
    musicTagRelations,
    musicTagCategories,

    // namespace available for all other module stores ==>
    aswGlobalStoreSpace: {
      namespaced: true,

      actions: {
        asw_log: {
          root: true,
          handler(namespacedContext, payload) {
            if (typeof namespacedContext != "undefined") {
              //console.log(this);
              var [str, obj, importance] = payload;
              tb._log("GStore] " + str, obj, importance);
            }
          },
        },
      },
    },
  },
  //
  //
});
