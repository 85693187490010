<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="edit"
      :id="id"
      headerTxt="User"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="receiveFormValues"
    />
  </div>
</template>

<script>
import userService from "@/services/userService.js";
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserEdit",
  components: { genericDialogForm },
  props: ["id"],
  data() {
    return {
      objFields: [
        {
          name: "name",
          label: "user name",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "first_name",
          label: "first name",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "last_name",
          label: "last name",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "remarks",
          label: "remarks",
          type: "text",
          value: "",
          toBeSend: 1,
        },

        {
          name: "userStatus_id",
          label: "user status",
          type: "fk_int",
          dataSrc: "userStatus",
          value: "",
          toBeSend: 1,
        },
        {
          name: "UserType_id",
          label: "user type",
          type: "fk_int",
          dataSrc: "UserType",
          value: "",
          toBeSend: 1,
        },

        { name: "email", label: "email", type: "", value: "", toBeSend: 1 },
        {
          name: "accountConfirmed",
          label: "account confirmed",
          type: "list",
          dataSrc: [
            { value: 0, label: "no" },
            { value: 1, label: "yes" },
          ],
          value: "",
          toBeSend: 1,
        },
        {
          name: "password",
          label: "password",
          type: "password",
          value: "",
          toBeSend: 1,
        },
        {
          name: "c_password",
          label: "confirm password",
          type: "password",
          value: "",
          toBeSend: 1,
        },
      ],
      User: null,
      dataReady: false,
    };
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  created() {
    this.$_log("11 views User layout edit amoId:" + this.id);
    userService
      .getUserDetails(this.id)
      .then((r) => {
        this.$_log("11 Just after User....");
        this.$_log("11 created response:", r.data.user_data[0]);
        this.User = r.data.user_data[0];
        this.fillCurrObjWithData(r.data.user_data[0]);
        this.dataReady = true;
      })
      .catch((error) => {
        this.$_log(error);
      });
  },
  methods: {
    receiveFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      // send data to the API server ...
      const obj = {
        user_id: this.id,
        name: this.objFields[0].value,
        first_name: this.objFields[1].value,
        last_name: this.objFields[2].value,
        remarks: this.objFields[3].value,
        userStatus_id: this.objFields[4].value,
        UserType_id: this.objFields[5].value,
        email: this.objFields[6].value,
        accountConfirmed: this.objFields[7].value,
        //password: this.objFields[8].value,
      };

      this.$_log(
        "Users:update doSubmit - pass:" +
          this.objFields[8].value +
          " c_pass:" +
          this.objFields[9].value
      );
      //check password ....
      var doNotSend = 0;
      if (this.objFields[8].toBeSend == 1) {
        obj.password = this.objFields[8].value;
        if (obj.password != this.objFields[9].value || obj.password == "") {
          doNotSend = 1;
          var n = {
            type: "problem",
            message: "Passwords doesnt match or empty!",
          };
        }
      }

      this.$_log("Users:update doSubmit - objectToBeSent:", obj);
      if (doNotSend == 1) {
        this.addNotificationMessage(n);
      } else {
        userService
          .setUserDetails(obj)
          .then((response) => {
            this.$_log("asw response.data:", response.data);
            //todo: add notification !!!

            var n = {
              type: "success",
              message: "You just correctly updated user data.",
            };
            this.addNotificationMessage(n);

            this.$router.push({
              name: "userView",
              params: { pageNr: 0 },
            });
          })
          .catch((error) => {
            this.$_log("asw error:", error);
          });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    fillCurrObjWithData(d) {
      for (var i = 0; i < this.objFields.length; i++) {
        for (const [key, value] of Object.entries(d)) {
          //this.$_log(key + " =====> " + value);
          if (this.objFields[i].name == key) {
            //this.$_log("OOOOOK: " + key + " =====> " + value);
            this.objFields[i].value = value;
          }
        }
      }
    },
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
  },
};
</script>
<style scoped></style>
