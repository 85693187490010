import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiUser = axios.create({
  //baseURL: 'http://api.muzaic.biz:49152',
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  /* ========= REQUIRE AUTHENTICATION ===========> */
  getUserTypeOperationList(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];
    vueApp.$_log("===========================");
    return apiUser.post("/admin/gutol/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  getUserTypeOperation(utoId) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.get("/admin/guto/" + utoId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  insertUserTypeOperation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.post("/admin/iuto/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  updateUserTypeOperation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.put("/admin/uuto/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  deleteUserTypeOperation(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.delete("/admin/duto/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
