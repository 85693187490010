<template>
  <div v-if="dataReady">
    <label :for="uuid">{{ listLabel }}</label>
    <div class="idf_select">
      <select
        :id="uuid"
        v-model="selectedItem"
        :name="elName"
        @change="onChange($event)"
      >
        <option v-for="(t, index) in dataTab" :key="index" :value="t.value">
          {{ t.label }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import UniqueID from "@/features/UniqueID";
import { mapGetters } from "vuex";

export default {
  name: "DropDownSimpleList",
  props: {
    listLabel: String,
    srcTab: Array,
    selected: Number,
    elName: String,
  },
  data() {
    return {
      dataTab: null,
      selectedItem: "",
      dataReady: false,
    };
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
  created() {
    this.$_log(
      "DropDownSimpleList.vue] get data according to the received params...:",
      this.srcTab
    );
    this.dataTab = this.srcTab;
    this.selectedItem = this.selected;
    this.dataReady = true;
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    onChange(event) {
      this.$_log(event.target.name + " - " + event.target.value);

      this.$emit("fn001", {
        name: event.target.name,
        value: event.target.value,
      });
    },
  },
};
</script>

<style scoped></style>
