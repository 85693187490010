import dbToolsService from "@/services/dbToolsService.js";

export const namespaced = true;

export const state = {
  usedTables: [],
};

export const getters = {
  dictionary(state, tabName) {
    for (var i = 0; i < state.usedTables; i++) {
      if (state.usedTables[i].name == tabName) {
        return state.usedTables[i].data;
      }
    }
    return [];
  },
};

export const mutations = {
  REGISTER_TABLE_USAGE(state, data) {
    state.usedTables.push(data);
  },
  /*
  PUSH(state, notification) {
    
    state.notifications.push({
      ...notification,
      id: nextId++,
    });
  },
  DELETE(state, notificationToRemove) {
    
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== notificationToRemove.id
    );
  },
  */
};
export const actions = {
  getDictionaryTable({ dispatch, commit }, tabName) {
    return new Promise((resolve, reject) => {
      dbToolsService
        .getSimpleTableContent(tabName)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          commit("REGISTER_TABLE_USAGE", { name: tabName, data: r.data.data });
          resolve(r.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //
  getSTC({ dispatch, commit }, jsonData) {
    return new Promise((resolve, reject) => {
      dbToolsService
        .getSTC(jsonData)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          commit("REGISTER_TABLE_USAGE", {
            name: jsonData.tableName,
            data: r.data.data,
          });
          resolve(r.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  //
  getQueryData({ dispatch, commit }, jsonData) {
    return new Promise((resolve, reject) => {
      dbToolsService
        .getQueryData(jsonData)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          commit("REGISTER_TABLE_USAGE", {
            name: jsonData.tableName,
            data: r.data.data,
          });
          resolve(r.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
