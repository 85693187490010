<template>
  <div class="idpBbkMsg">
    <p>thanks '{{ provider }}'</p>
  </div>
</template>

<script>
export default {
  props: ["provider"],
};
</script>

<style scoped>
.idpBbkMsg {
  height: 99vh;
  width: 99vw;
  position: relative;
  border: 3px solid green;
}
.idpBbkMsg p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
