<template>
  <dropdownMenu
    name="userProfile"
    :title="title"
    :items="UserProfileServices"
  />
</template>

<script>
import dropdownMenu from "@/components/navigation/DropdownMenu_Layout.vue";
import { mapGetters } from "vuex";

export default {
  name: "UserProfileNavBar",
  props: { title: String },
  data() {
    return {
      UserProfileServices: [
        {
          title: "change password",
          link: "changePass",
          icon: "lock-keyhole_w.svg",
          fun: () => {
            this.$_log("ASW lets change user password");

            this.$router.push({
              name: "resetPassword_UserLogedIn",
              params: { token: this.userToken },
            });
          },
        },
        {
          title: "Settings",
          link: "accountSettings",
          icon: "",
          fun: () => {
            this.$_log("ASW lets display current user settings ...");

            this.$router.push({
              name: "userDetails",
              params: { id: Number(this.currentUserId) },
            });
          },
        },
        {
          title: "log out",
          link: "logout",
          icon: "arrow-right-from-bracket_w.svg",
          fun: () => {
            this.$_log("ASW lets logout!");

            this.$store
              .dispatch("userModule/logout")
              .then(() => {
                this.$router.push({
                  path: "/",
                  replace: true,
                });
              })
              .catch((error) => {
                this.$_log(error);
              });
          },
        },
        /*
        {
          title: "aswTest",
          link: "aswTest",
          icon: "arrow-right-from-bracket_w.svg",
          fun: () => {
            console.log("abc123...");
            alert("aaa:" + Date.now());
          },
        },
		*/
      ],
    };
  },
  components: {
    dropdownMenu,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
      "userType",
    ]),
  },
};
</script>

<style scoped></style>
