import { createConfirmDialog } from "vuejs-confirm-dialog";
import ModalDialog from "@/components/dialogs/modal/CofirmDialog.vue";

const useConfirmBeforeAction = (action, cancelAction, props) => {
  const { reveal, onConfirm, onCancel } = createConfirmDialog(
    ModalDialog,
    props
  );

  reveal();

  onConfirm(action);
  onCancel(cancelAction);
};

export default useConfirmBeforeAction;
