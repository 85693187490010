import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiUser = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  /* ========= REQUIRE AUTHENTICATION ===========> */
  getSimpleTableContent(tabName) {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.get("/admin/gst/" + tabName, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  //
  getSTC(jD) {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.post("/admin/gst/", jD, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  //
  getQueryData(jD) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUser.post("/admin/gqd/", jD, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
