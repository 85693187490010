<template>
  <div v-if="dataReady" class="applicationModuleViewContainer">
    <listHeader
      pLabel="ApplicationModule"
      pLinkName="appModuleAdd"
      dataSrc="ApplicationModule"
      :searchVars="searchVariables"
      @searchValues="Search"
    />
    <div class="tabDataContainer">
      <table>
        <!-- 
		<tr>
          <th>lp.</th>
          <th>application module</th>
          <th class="wideTabColumn">remarks</th>
          <th>functions</th>
        </tr>
		-->

        <tabHeader
          :tableFields="tabFields"
          :fieldsToHide="tabFieldsToHide"
          :fieldsClass="tabFieldsClass"
          :fieldsTranslation="tabFieldsTranslations"
        />

        <tr
          v-for="(r, r_index) in tableContent"
          :key="r.id"
          :class="computedCssClass(r_index)"
        >
          <td>{{ r_index }}</td>
          <td>{{ r.name }}</td>
          <td>{{ r.remarks }}</td>

          <td>
            <router-link :to="{ name: 'appModuleEdit', params: { id: r.id } }"
              >edit</router-link
            >
            /
            <a
              @click="deleteElement($event, r.id, r.name)"
              style="cursor: pointer"
            >
              delete
            </a>
          </td>
        </tr>
      </table>
    </div>

    <pagination
      :pageNr="pageNr"
      :totalPages="totalPages"
      :nrRowsTotal="nrRowsTotal"
      linkName="appModules"
      :nrEOP="nrElOnPage"
    />
  </div>
</template>

<script>
import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";

import { mapGetters, mapActions } from "vuex";
import { watchEffect } from "vue";
import appModule from "@/services/appModuleService.js"; // access to the APIs
import pagination from "@/components/listView/tabPagination.vue";
import listHeader from "@/components/listView/listHeader.vue";
import tabHeader from "@/components/listView/tabHeader.vue";

export default {
  name: "ApplicationModuleView",
  components: {
    listHeader,
    tabHeader,
    pagination,
  },
  props: ["id", "msg", "pageNr"],
  data() {
    return {
      users: null,

      usertype: null,
      tableName: "ApplicationModule",
      tableContent: null,

      tabFields: null,
      tabFieldsToHide: [],
      tabFieldsClass: [],
      tabFieldsTranslations: [],

      dataReady: false,

      // --> fields to be search
      searchVariables: {
        name: {
          label: "name",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        remarks: {
          label: "remarks",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
      },
      // --< fields to be search
      nrRowsTotal: 0,
      nrElOnPage: 10,
      orderBy: "id",
    };
  },
  created() {
    this.$_log("pageNr:" + this.pageNr);
    //this.$_log("TOKEN >>>>" + this.$store.state.userModule.currentLoggedUser.token);
    this.$_log("TOKEN >>>>" + this.userToken);
    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.nrRowsTotal / this.nrElOnPage);
    },
    ...mapGetters("userModule", ["userToken", "isUserLoggedIn"]),
  },

  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    ...mapActions("appMod", ["getAppModuleList"]),
    deleteElement(event, elemId, elemName) {
      this.$_log("event:" + event);
      this.$_log("delete elem:" + elemId);
      ///
      useConfirmBeforeAction(
        function () {
          //alert("action confirmed!");
          appModule
            .deleteAppModule(elemId)
            .then((response) => {
              this.$_log("asw response.data:", response.data);

              var n = {
                type: "success",
                message:
                  "You just correctly removed application module '" +
                  elemName +
                  "'",
              };
              this.addNotificationMessage(n).catch((err) => {
                this.$_log("addNotificationMessage 00 error:", err);
              });
            })
            .then(() => {
              this.getData();
              /*
              this.$router.push({
                name: "appModules",
                params: { pageNr: 0 },
              });
			  */
            })
            .catch((error) => {
              this.$_log("asw deleteAppModule error:", error);
            });
        }.bind(this), // context has been changed so i have to "bind(this)"" to the function ....
        function () {
          this.$_log("action canceled ...");
        },
        {
          // message to be displayed
          question:
            "Do you realy wnat to delete application module `" +
            elemName +
            "` ?",
        }
      );
      ///
    },
    Search(data) {
      this.$_log("Search data:", data);
      this.searchVariables = data;
      this.getData();
    },
    getData() {
      this.$_log("Just before getAppModuleList....getData()...");
      var jo = {
        // search params ->
        name: this.searchVariables.name.value,
        remarks: this.searchVariables.remarks.value,
        // search params <-

        pageNr: this.pageNr,
        nrElOnPage: this.nrElOnPage,
        orderBy: this.orderBy,
      };

      this.getAppModuleList(jo)
        .then((r) => {
          this.$_log("Just before getAppModuleList....");
          this.$_log("created response:", r);

          this.nrRowsTotal = r.nrRowsTotal;
          this.tableContent = r.data;
          this.tabFields = r.fields;
          //this.tabFieldsToHide.push({ name: "remarks" });
          this.tabFieldsClass.push({ name: "remarks", class: "wideTabColumn" });
          this.tabFieldsTranslations.push({
            name: "functions",
            value: "functions",
          });

          this.$_log(
            "created[" +
              this.tableName +
              "] this.tableContent.length:" +
              this.tableContent.length +
              " nrRowsTotal:" +
              this.nrRowsTotal
          );

          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    computedCssClass(i) {
      if (i % 2 === 0) {
        return "";
      } else {
        return "odd";
      }
    },
  },
};
</script>

<style scoped></style>
