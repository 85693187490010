<template>
  <div v-if="dataReady">
    <div v-if="isUserLoggedIn" class="A">
      <AdminPanel msg="dashboard" />
    </div>
    <div v-else class="A">
      <template v-if="typeof this.$route.meta.idp != 'undefined'">
        <!-- 
		{{ this.$route.name }} [{{ this.$route.meta.idp }}--{{
          typeof this.$route.meta.idp
        }}]
		-->
        <template v-if="this.$route.meta.idp == true">
          <router-view />
        </template>
      </template>
      <template v-else>
        <LandingPage00 msg="" />
      </template>
    </div>
  </div>

  <!-- ASW notifications and dialog boxes -> -->
  <NotificationContainer />
  <DialogsWrapper />
  <!-- ASW notifications and dialog boxes -< -->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import NotificationContainer from "@/components/notificationBar/NotificationContainer.vue";
import AdminPanel from "@/views/AdminPanel.vue";
import LandingPage00 from "@/views/Landing00.vue";
//import loginIdP from "@/views/loginIdP.vue";

export default {
  name: "MainTemplate",
  //props: ["provider"],
  data() {
    return {
      dataReady: false, // do not display template until data is ready!!
    };
  },
  async created() {
    // check if user is logged in or if he have old session ...
    // additionally check if we can refresh session token and refresh it if possible
    // this action is also updating userModule state object
    this.$_log("App.vue created 0 ...");
    await this.isUserLoggedIn_Action_P().then(
      (response) => {
        this.$_log(
          "App.vue] AAA. response:" +
            response +
            " this.currentUserId:" +
            this.currentUserId +
            " "
        );
        this.$_log("============================================");
        this.$_log(" this.$route.path:", this.$route.path);
        this.$_log("============================================");
        this.$_log("============================================");
        this.$_log(" this.$router.query:", this.$route.query);
        this.$_log("============================================");
        this.dataReady = true;

        if (response === true && this.$route.path == "/") {
          this.$_log(
            "App.vue] requested route was :" +
              this.$route.path +
              " but user is already logged in so ... lets jump to the dashboard."
          );

          this.$router.push({
            name: "UserDashboard",
            params: { id: Number(this.currentUserId) },
          });
        } else if (response === false) {
          this.$_log(
            "ASW 'there is no refresh token'... so user is still not authenticated...."
          );
        }
      },
      (error) => {
        this.$_log("App.vue] App.vue. error:", error.response.data.error);
      }
    );
    this.$_log("App.vue created 10 ...");
  },

  components: {
    NotificationContainer,
    AdminPanel,
    LandingPage00,
    //loginIdP,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    ...mapActions("userModule", ["isUserLoggedIn_Action_P"]),
  },
};
</script>

<style src="@/assets/css/internal_dialogform_0.css"></style>
<style>
/* ASW 2023-01-01 >>>> */
body {
  display: block;
  margin: 0px;
}

.A {
  position: relative;
}
/* ASW 2023-01-01 <<<< */

/* Vue Project defaults >>>> */
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font: normal normal 600 18px/27px Poppins;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

/* Vue Project defaults <<<< */

a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

/* === TABLES WITH LIST OF DATA === > */
.tabDataContainer {
  /*position: relative;*/
  display: flex;
  width: 100%;
  /*height: 100%;*/
}

.tabDataContainer table {
  margin: 0 auto;

  text-align: left;
  font: normal normal 200 14px/21px Poppins;
  letter-spacing: 0px;
  color: #000;
  padding-left: 5px;

  border-collapse: collapse;
}

.tabDataContainer table,
th,
td {
  border: 0px solid;
}

.tabDataContainer table th {
  background-color: rgb(208, 210, 208);
  padding-left: 5px;
}

.tabDataContainer table tr {
  background-color: #fff;
  padding-left: 5px;
}

.tabDataContainer table tr.odd {
  background-color: rgb(231, 233, 231);
  padding-left: 5px;
}

.tabDataContainer table tr td.odd {
  background-color: rgb(231, 233, 231);
}

.wideTabColumn {
  width: 300px;
}
/* === TABLES WITH LIST OF DATA === < */

/* === TABLE PAGINATION === > */
.tabPaginationContainer {
  display: flex;
  width: 100%;
}
.tabPaginationContainer table {
  margin: 0 auto;
  text-align: left;
  font: normal normal 200 14px/21px Poppins;
  /* letter-spacing: 0px; */
  /* color: #000; */
  padding-left: 5px;
  border-collapse: collapse;
}
</style>
