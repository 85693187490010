import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiClient = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  /* ========= REQUIRE AUTHENTICATION ===========> */
  getClients() {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];
    return apiClient.get("/admin/gcl/", {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  // =========================================================

  // =========================================================
  getClientListByParams(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiClient.post("/admin/gcl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getClientList(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiClient.post("/admin/gcl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getClient(clientId) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiClient.get("/admin/gc/" + clientId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createClient(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiClient.post("/admin/cc/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateClient(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiClient.put("/admin/uc/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteClient(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiClient.delete("/admin/dc/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
