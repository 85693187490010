import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiMmpTagCat = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getMmpTagCatList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagCat.post("/admin/gmmptcl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getMmpTagCat(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiMmpTagCat.get("/admin/gmmptc/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createMmpTagCat(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagCat.post("/admin/cmmptc/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateMmpTagCat(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagCat.put("/admin/ummptc/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteMmpTagCat(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiMmpTagCat.delete("/admin/dmmptc/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
