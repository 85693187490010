<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="add"
      headerTxt="User"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="validateFormValues"
    />
  </div>
</template>
<script>
import userService from "@/services/userService.js";
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import { mapActions } from "vuex";
//import form from "vuejs-form";
import Validator from "validatorjs";

export default {
  name: "UserAdd",
  components: {
    genericDialogForm,
  },
  props: ["msg"],
  data() {
    return {
      objFields: [
        {
          name: "name",
          label: "user name",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "first_name",
          label: "first name",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "last_name",
          label: "last name",
          type: "text",
          value: "",
          toBeSend: 1,
        },
        {
          name: "remarks",
          label: "remarks",
          type: "text",
          value: "",
          toBeSend: 1,
        },

        {
          name: "userStatus_id",
          label: "user status",
          type: "fk_int",
          dataSrc: "userStatus",
          value: "",
          toBeSend: 1,
        },
        {
          name: "UserType_id",
          label: "user type",
          type: "fk_int",
          dataSrc: "UserType",
          value: "",
          toBeSend: 1,
        },

        {
          name: "email",
          label: "email",
          type: "text",
          value: "",
          error: "",
          toBeSend: 1,
        },

        {
          name: "password",
          label: "password",
          type: "password",
          value: "",
          error: "",
          toBeSend: 1,
        },
        {
          name: "password_confirmation",
          label: "confirm password",
          type: "password",
          value: "",
          error: "",
          toBeSend: 1,
        },
      ],
      submitButton: { target: "" },
      dataReady: false,
      /*
      form: form({ email: "", password: "", password_confirmation: "" })
        .rules({
          email: "email|min:5|required",
          password: "required|min:5|confirmed",
        })
        .messages({
          "email.email": "Email field must be an email (durr)",
          "password.confirmed":
            "Whoops, :attribute value does not match :confirmed value",
        }),
		*/
    };
  },
  created() {
    this.$_log("views user:add new");
    this.dataReady = true;
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    goBack() {
      this.$router.go(-1);
    },
    onFn001(v) {
      this.$_log("onFn001 elName:" + v.name + " elValue:" + v.value);
      //this.$_log("onFn001 elValue:" + v);
    },
    validateFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      /////////////////////////////////////////////////////////////////////////////////
      //let validation = new Validator(data, rules [, customErrorMessages]);
      this.objFields[6].error = this.objFields[7].error = "";

      let data = {
        email: this.objFields[6].value,
        password: this.objFields[7].value,
        password_confirmation: this.objFields[8].value,
      };
      this.$_log(data);

      let rules = {
        email: "required|email",
        password: "confirmed|min:4",
        //password_confirmation: "confirmed|min:4",
      };
      let validation = new Validator(data, rules);

      //validation.fails(); // true
      if (validation.passes()) {
        userService
          .checkEmail(data.email)
          .then((r) => {
            this.$_log(r.data);
            if (r.data.mailIsInUse == 0) {
              this.objFields[6].error = this.objFields[7].error = "";
              this.doSubmit(); // >>>>>> go ahead !!
            } else {
              this.objFields[6].error =
                "This email is already in use. Please use other email.";
            }
          })
          .catch((err) => {
            this.$_log(err);
          });
      } else {
        this.$_log("validation failed!");
        // Error messages

        if (validation.errors.has("email")) {
          this.$_log("error:" + validation.errors.get("email")); // 'The email format is invalid.'
          this.objFields[6].error = validation.errors.get("email").join(",");
        }
        if (validation.errors.has("password")) {
          this.$_log("error:" + validation.errors.get("password")); // 'The email format is invalid.'
          this.objFields[7].error = validation.errors.get("password").join(",");
        }
        this.$_log("error:", validation.errors.all()); // returns an array of all email error messages
      }

      /////////////////////////////////////////////////////////////////////////////////

      //this.doSubmit();
    },
    doSubmit() {
      const obj = {
        name: this.objFields[0].value,
        first_name: this.objFields[1].value,
        last_name: this.objFields[2].value,
        remarks: this.objFields[3].value,
        userstatus_id: this.objFields[4].value,
        usertype_id: this.objFields[5].value,
        email: this.objFields[6].value,

        //password: this.objFields[7].value,
      };

      var doNotSend = 0;
      if (this.objFields[7].toBeSend == 1) {
        this.$_log(
          "password:" +
            this.objFields[7].value +
            " password_confirmation:" +
            this.objFields[8].value
        );
        obj.password = this.objFields[7].value;
        if (obj.password != this.objFields[8].value || obj.password == "") {
          doNotSend = 1;
          var n = {
            type: "problem",
            message: "Passwords doesnt match or empty!",
          };
        }
      }

      this.$_log("client:add new onSubmit - User:", obj);
      this.$_log("Users:update doSubmit - objectToBeSent:", obj);
      if (doNotSend == 1) {
        this.addNotificationMessage(n);
      } else {
        userService
          .createUser(obj)
          .then((response) => {
            this.$_log("asw response.data:", response.data);
            //todo: add notification !!!

            var n = {
              type: "success",
              message: "You just correctly updated user data.",
            };
            this.addNotificationMessage(n);

            this.$router.push({
              name: "userView",
              params: { pageNr: 0 },
            });
          })
          .catch((error) => {
            this.$_log("asw error:", error);
          });
      }
      /*
      insertAppModuleOperation
        .setUserDetails(usr)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //this.user = response.data.user_data[0];
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
		*/
    },
  },
};
</script>

<style scoped></style>
