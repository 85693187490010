<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="edit"
      :id="id"
      headerTxt="Module Operation"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="receiveFormValues"
    />
  </div>
</template>
<script>
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import amoService from "@/services/appModuleOperationService.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppModuleOperationEdit",
  components: { genericDialogForm },
  props: ["id"],
  data() {
    return {
      objFields: [
        { name: "remarks", label: "remarks", type: "string", value: "" },
        {
          name: "ApplicationModule_id",
          label: "app module",
          type: "fk_int",
          dataSrc: "ApplicationModule",
          value: "",
        },
        {
          name: "Operation_id",
          label: "operation",
          type: "fk_int",
          dataSrc: "Operation",
          value: "",
        },
        { name: "url", label: "url", type: "text", value: "" },
        { name: "method", label: "method", type: "text", value: "" },
        { name: "params", label: "params", type: "text", value: "" },
      ],
      submitButton: { target: "" },
      dataReady: false,
    };
  },
  created() {
    this.$_log("11 views AppModuleOperation layout edit amoId:" + this.id);
    this.getAppModuleOperation(this.id)
      .then((r) => {
        this.$_log("11 Just after getAppModuleOperation....");
        this.$_log("11 created response A:", r.data);

        this.fillCurrObjWithData(r.data[0]);
        this.$_log("11 created response B:", this.objFields);

        this.dataReady = true;
      })
      .catch((error) => {
        this.$_log(error);
      });
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  methods: {
    receiveFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      // send data to the API server ...
      const obj = {
        id: this.id,
        remarks: this.objFields[0].value,
        ApplicationModule_id: this.objFields[1].value,
        Operation_id: this.objFields[2].value,
        url: this.objFields[3].value,
        method: this.objFields[4].value,
        params: this.objFields[5].value,
        //user_created: this.currentUserId,
      };

      this.$_log("AppModuleOperation:update doSubmit - objectToBeSent:", obj);

      amoService
        .updateAppModuleOperation(obj)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!

          var n = {
            type: "success",
            message: "You are correctly updated module operation.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "appModuleOperationView",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
    fillCurrObjWithData(d) {
      for (var i = 0; i < this.objFields.length; i++) {
        for (const [key, value] of Object.entries(d)) {
          this.$_log(key + " =====> " + value);
          if (this.objFields[i].name == key) {
            this.$_log("OOOOOK: " + key + " =====> " + value);
            this.objFields[i].value = value;
          }
        }
      }
    },
    ...mapActions("appModOp", ["getAppModuleOperation"]),
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
  },
};
</script>
<style scoped></style>
