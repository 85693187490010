<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <form @submit.prevent="onSubmit">
        <input type="hidden" name="token" :value="rpToken" />
        <div>
          <label>Please enter new password.</label>
        </div>
        <div>
          <label>Password</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.password"
            type="password"
            placeholder="password"
            class="dlg_pwd"
          />
        </div>
        <div>
          <label>Confirm password</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.passwordC"
            type="password"
            placeholder="password"
            class="dlg_pwd"
          />
        </div>

        <div class="dlg_msg" v-if="!isUserLoggedIn">
          <p>
            Do you want to log in?<br />
            <router-link to="/login">Go to log in page</router-link>
          </p>
        </div>
        <!--
        <div style="position: relative">
          <button type="submit" class="dlg_submit">Send</button>
        </div>
		-->
        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn">Save</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResetPasswordRequestDialog",
  props: {
    msg: String,
    rpToken: String,
  },
  data() {
    return {
      mCurrentUser: {
        email: "",
        password: "",
        passwordC: "",
      },
    };
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    onSubmit() {
      const usr = {
        password: this.mCurrentUser.password,
        token: this.rpToken,
      };

      this.$_log("ResetPassword.vue] onSubmit - User:", usr);
      this.$store
        .dispatch("userModule/resetPassword", usr)
        .then(() => {
          this.$_log("ResetPassword.vue] onSubmit - then ...:");
          if (this.isUserLoggedIn) {
            this.$router.push({
              name: "UserDashboard",
              params: { id: Number(this.currentUserId) },
            });
          } else {
            this.$router.push({
              name: "login",
            });
          }
        })
        .catch((error) => {
          this.$_log("ResetPassword onSubmit error:", error);
          /*
				this.$router.push({
				  name: "ErrorDisplay",
				  params: { error: error },
				});*/
        });
    },
    //...mapActions("notificationModule", ["remove"]),
    ...mapActions("userModule", ["login"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped></style>
