<template>
  <div class="dlg_form_container">
    <div class="dlg_c_l1">
      <p class="dlg_form_header">{{ msg }}</p>
    </div>
    <div class="dlg_c_l2">
      <form @submit.prevent="onSubmit">
        <div>
          <label>E-mail address</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.email"
            type="text"
            placeholder="test@test.com"
          />
        </div>

        <div>
          <label>Password</label>
        </div>
        <div>
          <input
            v-model="mCurrentUser.password"
            type="password"
            placeholder="password"
            class="dlg_pwd"
          />
        </div>
        <div class="dlg_msg">
          <p>
            Do you want to register?<br />
            <router-link to="/register">Go to register page</router-link>
          </p>
        </div>
        <!--
		<div style="position: relative">
          <button type="submit" class="dlg_submit">Log in</button>
        </div>
		-->
        <div class="dlg_submit_line">
          <button type="submit" class="dlg_submit_btn">Log in</button>
        </div>
      </form>
    </div>
  </div>

  <div class="dlg_below">
    <router-link to="/rpr">I forgot my passowrd</router-link>
  </div>

  <div>
    <button @click="useAuthProvider('apple', null)">auth with Apple</button>
    <button @click="useAuthProvider('facebook', null)">
      auth with Facebook
    </button>
    <button @click="useAuthProvider('google', null)">auth with Google</button>
  </div>
</template>

<script>
//import { mapState } from "vuex";
import { mapGetters, mapActions } from "vuex";

// 20230523 universal social auth->
import axios from "axios";
import { Providers } from "universal-social-auth";
import UniversalSocialauth from "universal-social-auth";
import config from "@/config/config.js";

const USAuth_options = {
  providers: config.UniversalSocialauth_idProviders,
};
const Oauth = new UniversalSocialauth(axios, USAuth_options);

// 20230523 <

export default {
  name: "LoginDialog",
  props: {
    msg: String,
  },
  data() {
    return {
      mCurrentUser: {
        username: "",
        email: "",
        password: "",
      },
      //universal-social-auth ==>
      responseData: {},
      //<==
    };
  },
  created() {
    this.$_log("LoginDialog] ======");
    this.$_log("LoginDialog] received params:", this.$route.query);
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    onSubmit() {
      const usr = {
        ...this.mCurrentUser,
        loginFormId: -10,
      };

      this.$_log("LoginDialog.vue] onSubmit - User:", usr);
      this.$store
        .dispatch("userModule/login", usr)
        //.dispatch("loginUser", usr)
        .then(() => {
          this.$_log(
            "LoginDialog.vue] onSubmit - then logged username:" +
              this.currentUsername +
              " user_id:" +
              this.currentUserId +
              " user.token:" +
              this.userToken
          );

          this.$router.push({
            name: "UserDashboard",
            params: { id: this.currentUserId },
          });
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    useAuthProvider(provider, proData) {
      const pro = proData;
      this.$_log(
        "LoginDialog] ====== lets try to authenticate using external authentication provider:" +
          provider
      );
      const ProData = pro || Providers[provider];
      Oauth.authenticate(provider, ProData)
        .then((response) => {
          const rsp = response;
          //console.log("Auth provider:" + provider + " response:", response);
          if (rsp.code) {
            this.responseData.code = rsp.code;
            this.responseData.provider = provider;
            this.useSocialLogin(provider);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    useSocialLogin(provider) {
      /*
      console.log(
        "ooook so we can use 'socialLogin'  data[" +
          provider +
          "] :) code:" +
          this.responseData.code
      );
	  */
      let data = {
        provider: this.responseData.provider,
        code: this.responseData.code,
        serviceName: "muzaic.biz",
      };
      switch (provider) {
        case "google": {
          this.loginUsingIdProviderData(data)
            .then(() => {
              //console.log("Current user id:" + this.currentUserId);
              this.$router.push({
                name: "UserDashboard",
                params: { id: this.currentUserId },
              });
            })
            .catch((err) => {
              console.log(err);
            });

          break;
        }
        case "facebook": {
          break;
        }
        case "apple": {
          break;
        }
      }
    },

    //...mapActions("notificationModule", ["remove"]),
    ...mapActions("userModule", ["login", "loginUsingIdProviderData"]),
  },
};
</script>

<style src="@/assets/css/dialogform_0.css"></style>
<style scoped></style>
