import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiStream = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  /*
  //designed to save 'creations' prepared by mobile users but can be used by any MuzaicBiz user ...
  saveUserCreations(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];
    vueApp.$_log("===========================");
    return apiUser.post("/admin/suc", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  */
  // -----------------------------------------------------

  getStreamsList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.post("/admin/gstrl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getSingleStream(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.get("/admin/gstr/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  updateSingleStream(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.put("/admin/ustr/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  deleteSingleStream(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.delete("/admin/dstr/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  createSingleStream(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.post("/admin/cstr/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // ///////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////
  // /// =>
  getStreamTags() {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.post("/admin/mb/getTags/", {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStreamMotives(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.post("/admin/mb/getMotives/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  getStreamPlaylists(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiStream.post("/admin/mb/getPlaylists/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // /// <=
};
