//var axios = require("axios");

var toolBox = function () {};

/*
 */
toolBox.prototype._log = function (str, obj, importance) {
  var enableLog = 1; //1|0
  var now = new Date();

  var t = toolBox.prototype.formatDate(now, "yyyy-MM-dd hh:mm:ss");
  var prefix = "" + t + "] ";
  var resp = "";
  if (typeof importance !== "undefined") {
    /*
	//var chalk = require("chalk");
    switch (importance) {
      case 1: {
        resp = prefix + "" + chalk.blue(str);
        break;
      }
      case 2: {
        resp = prefix + "" + chalk.green(str);
        break;
      }
      case 3: {
        resp = prefix + "" + chalk.yellow.bgRed.bold(str);
        break;
      }
      default: {
        resp = prefix + "" + str;
        break;
      }
    }
	*/
    resp = prefix + "" + str;
  } else {
    resp = prefix + "" + str;
  }

  if (enableLog == 1) {
    if (resp != "") {
      console.log(resp);
    }
    if (typeof obj != "undefined") {
      if (obj != null) console.log(prefix + "", obj);
    }
  }
};

toolBox.prototype.toHHMMSS = function (s) {
  var sec_num = parseInt(s, 10); // don't forget the second param
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

toolBox.prototype.formatDate = function (x, y) {
  var z = {
    M: x.getMonth() + 1,
    d: x.getDate(),
    h: x.getHours(),
    m: x.getMinutes(),
    s: x.getSeconds(),
  };
  y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
    return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2);
  });

  return y.replace(/(y+)/g, function (v) {
    return x.getFullYear().toString().slice(-v.length);
  });
};

module.exports = new toolBox();
