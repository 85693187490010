<template>
  <div :style="style">
    <div>
      <label :for="uuid">{{ label }}</label>
    </div>

    <div>
      <input
        :name="name"
        :value="value"
        type="password"
        class="field"
        @input="$emit('update:name', $event.target.value)"
        :id="uuid"
        :aria-describedby="error ? `${uuid}-error` : null"
        :aria-invalid="error ? true : null"
        :disabled="disabled"
      />
      <p
        v-if="error"
        class="errorMessage"
        :id="`${uuid}-error`"
        aria-live="assertive"
      >
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
import UniqueID from "@/features/UniqueID";

export default {
  props: {
    label: { type: String, required: true },
    modelValue: { type: [String, Number], default: "" },
    name: { type: String, required: true },
    value: { type: [String, Number], default: "" },
    type: { type: [String], default: "text" },
    placeholder: { type: [String, Number], default: "" },
    error: { type: String, default: "" },
    disabled: { type: [String, Number, Boolean], default: false },
    style: { type: String, default: "" },
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
};
</script>

<style scoped></style>
