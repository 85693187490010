<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="edit"
      :id="id"
      headerTxt="Module Operation"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="receiveFormValues"
    />
  </div>
</template>
<script>
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import utoService from "@/services/userTypeOperationService.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserTypeOperationEdit",
  components: { genericDialogForm },
  props: ["id"],
  data() {
    return {
      objFields: [
        {
          name: "UserType_id",
          label: "user type",
          type: "fk_int",
          dataSrc: "UserType",
          value: "",
        },
        {
          name: "ApplicationModule_Operation_id",
          label: "app module operation",
          type: "fk_multiRelational",
          dataSrc: "ApplicationModule_Operation",
          getData: { id: "id", value: ["url", "method"] },
          value: "",
        },
      ],
      submitButton: { target: "" },
      dataReady: false,
    };
  },
  created() {
    this.$_log("11 views UserTypeOperation layout edit utoId:" + this.id);
    utoService
      .getUserTypeOperation(this.id)
      .then((r) => {
        this.$_log("11 Just after getUserTypeOperation....");
        this.$_log("11 created response A:", r.data.data[0]);

        this.fillCurrObjWithData(r.data.data[0]);
        this.$_log("11 created response B:", this.objFields);

        this.dataReady = true;
      })
      .catch((error) => {
        this.$_log(error);
      });
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  methods: {
    receiveFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      // send data to the API server ...
      const obj = {
        id: this.id,
        UserType_id: this.objFields[0].value,
        ApplicationModule_Operation_id: this.objFields[1].value,
        user_modified: this.currentUserId,
      };

      this.$_log("UserTypeOperation:update doSubmit - objectToBeSent:", obj);

      utoService
        .updateUserTypeOperation(obj)
        .then((response) => {
          this.$_log("asw response.data:", response.data);

          var n = {
            type: "success",
            message: "You are correctly updated usertype operation.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "userTypeOperationView",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
    fillCurrObjWithData(d) {
      for (var i = 0; i < this.objFields.length; i++) {
        for (const [key, value] of Object.entries(d)) {
          this.$_log(key + " =====> " + value);
          if (this.objFields[i].name == key) {
            this.$_log("OOOOOK: " + key + " =====> " + value);
            this.objFields[i].value = value;
          }
        }
      }
    },

    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
  },
};
</script>
<style scoped></style>
