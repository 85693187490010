import PageModule from "@/store/modules/generic/genericPageModule.js";
import userService from "@/services/userService.js";

var page = new PageModule();

const namespaced = true;

// ==============================================================================
// ==============================================================================
const state = {
  // currently LOGGED IN user
  currentLoggedUser: {
    loginFormId: -1,
    loggedIn: false,

    token: "",
    refreshToken: "",

    user_id: -1,
    usertype_id: -1,
    credentials: {},
    name: "",
    email: "",
    expires: "",
  },
  newTestAccountName: "", // hmm .... what was it for ???? to be checked
  checkingState: 0,
  ...page.state,
};

// ==============================================================================
// ==============================================================================
const getters = {
  ...page.getters,

  isTokenValid(state) {
    var d = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
    if (typeof state.currentLoggedUser.expires != "undefined") {
      if (state.currentLoggedUser.expires > d) {
        return true;
      } else {
        return false;
      }
    }
  },
  isUserLoggedIn(state) {
    var x = 0;
    if (x == 1) {
      //console.log("isUserLoggedIn??? no he is not!");
      return false;
    } else {
      if (state.currentLoggedUser.loggedIn === false) {
        return false;
      } else {
        return true;
      }
    }
  },

  userToken(state) {
    /*
    if (state.currentLoggedUser.token === "") {
      var u = localStorage.getItem("user");
      if (u != null) {
        var u0 = JSON.parse(u);
        return u0.token;
      } else {
        return "";
      }
    } else 
	*/
    return state.currentLoggedUser.token;
  },
  userRefreshToken(state) {
    /*
    if (state.currentLoggedUser.refreshToken === "") {
      console.log(
        "user getters 0 state does not have refresh token ... lets check local sorage..."
      );
      let u = localStorage.getItem("user");
      if (u != null) {
        let u0 = JSON.parse(u);
        console.log("user getters 0a");
        state.currentLoggedUser.refreshToken = u0.refreshToken; // ?
        return u0.refreshToken;
      } else {
        console.log("user getters 0b");
        return "";
      }
    } else {
      console.log("user getters 1");
      return state.currentLoggedUser.refreshToken;
    }
	*/
    return state.currentLoggedUser.refreshToken;
  },
  userType(state) {
    /*
    if (state.currentLoggedUser.usertype_id == -1) {
      var u = localStorage.getItem("user");
      if (u != null) {
        var u0 = JSON.parse(u);
        return u0.usertype_id;
      } else {
        return -1;
      }
    } else 
	*/
    return state.currentLoggedUser.usertype_id;
  },
  currentUsername(state) {
    /*
    if (state.currentLoggedUser.name === "") {
      var u = localStorage.getItem("user");
      if (u != null) {
        var u0 = JSON.parse(u);
        state.currentLoggedUser.name = u0.name; // ?
        return u0.name;
      } else {
        return "";
      }
    } else 
	*/
    return state.currentLoggedUser.name;
  },
  currentUserEmail(state) {
    /*
    if (state.currentLoggedUser.email === "") {
      var u = localStorage.getItem("user");
      if (u != null) {
        var u0 = JSON.parse(u);

        return u0.email;
      } else {
        return "";
      }
    } else 
	*/
    return state.currentLoggedUser.email;
  },
  currentUserId(state) {
    /*
    if (state.currentLoggedUser.user_id === "-1") {
      var u = localStorage.getItem("user");
      if (u != null) {
        var u0 = JSON.parse(u);
        return u0.user_id;
      } else {
        return "";
      }
    } else 
	*/
    return state.currentLoggedUser.user_id;
  },
  currentUserTypeId(state) {
    /*
    if (state.currentLoggedUser.usertype_id === "-1") {
      var u = localStorage.getItem("user");
      if (u != null) {
        var u0 = JSON.parse(u);
        return u0.usertype_id;
      } else {
        return "";
      }
    } else 
	*/
    return state.currentLoggedUser.usertype_id;
  },
};

// ==============================================================================
// ==============================================================================
const mutations = {
  ...page.mutations,

  LOG_IN(state, userData) {
    console.log(
      "store] mutations LOG_IN userData.name:" +
        userData.name +
        " userData.email:" +
        userData.email
    );

    state.currentLoggedUser = userData;
    state.currentLoggedUser.loggedIn = true;
    // register data also in localStorage !!
    localStorage.setItem("user", JSON.stringify(userData));
  },
  LOG_OUT(state) {
    //clear currentuser
    console.log("LOG_OUT!!!");
    state.currentLoggedUser.loginFormId = -1;

    state.currentLoggedUser.user_id = -1;
    state.currentLoggedUser.usertype_id = -1;
    state.currentLoggedUser.credentials = {};
    state.currentLoggedUser.name = "";
    state.currentLoggedUser.email = "";
    state.currentLoggedUser.token = "";
    state.currentLoggedUser.refreshToken = "";

    state.currentLoggedUser.expires = "";
    state.currentLoggedUser.loggedIn = false;

    localStorage.removeItem("user");
  },
  REGISTER_TEST_USER(state, data) {
    state.newTestAccountName = data.name;
  },
  SET_TOKEN(state, data) {
    // set state.currentLoggedUser based on localstorage
    const u = localStorage.getItem("user");
    if (u != null) {
      var u0 = JSON.parse(u);
      state.currentLoggedUser = u0;

      console.log(
        "storeUsers] mutation SET_TOKEN 0 state.currentLoggedUser:",
        state.currentLoggedUser
      );

      // then ->
      state.currentLoggedUser.token = data.token;
      state.currentLoggedUser.expires = data.expires;

      // then set localstorage based on currentUser ..

      localStorage.setItem("user", JSON.stringify(state.currentLoggedUser));
    }
  },
  SET_STATE_BASED_ON_LOCAL_STORAGE(state) {
    /*
    console.log(
      "lets update user state --------------------------------------"
    );
	*/
    var u = localStorage.getItem("user");
    if (u != null) {
      var u0 = JSON.parse(u);
      console.log("user mutation 'SET_STATE_BASED_ON_LOCAL_STORAGE'...");
      state.currentLoggedUser.token = u0.token;
      state.currentLoggedUser.refreshToken = u0.refreshToken; // ?

      state.currentLoggedUser.usertype_id = u0.usertype_id;
      state.currentLoggedUser.name = u0.name; // ?
      state.currentLoggedUser.email = u0.email;
      state.currentLoggedUser.user_id = u0.user_id;
      state.currentLoggedUser.usertype_id = u0.usertype_id;
    }
  },
};

// ==============================================================================
// ==============================================================================
const actions = {
  ...page.actions,
  async loginUsingIdProviderData({ commit, dispatch }, data) {
    let n = {};
    return await userService
      .loginUsingIdProviderData(data)
      .then((r) => {
        dispatch("asw_log", ["loginUsingIdProviderData response:", r.data], {
          root: true,
        });

        commit("LOG_IN", r.data);

        n = {
          type: "success",
          message: "You are correctly logged in",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        console.log("ASW grrrr error:", error);
        dispatch("asw_log", ["loginUsingIdProviderData error", error], {
          root: true,
        });

        if (typeof error.response != "undefined") {
          if (error.response.status == 403 && error.response.data.status) {
            n = {
              type: "error",
              message: "Incorrect credentials. Try one more time.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect credentials";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  async login({ commit, dispatch }, userCredentials) {
    var n = {};
    return await userService
      .login(userCredentials)
      .then((r) => {
        dispatch("asw_log", ["login response:", r.data], { root: true });
        commit("LOG_IN", r.data);
        n = {
          type: "success",
          message: "You are correctly logged in",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", ["login error", error], { root: true });

        if (typeof error.response != "undefined") {
          if (error.response.status == 403 && error.response.data.status) {
            n = {
              type: "error",
              message: "Incorrect credentials. Try one more time.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect credentials";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  //
  async logout({ commit, dispatch, getters }) {
    let n = {};
    //let rt = getters.userRefreshToken;

    dispatch("asw_log", ["logout"], { root: true });
    var x = 1;
    console.log("===");
    console.log(state.currentLoggedUser.name);
    console.log(state.currentLoggedUser.refreshToken);
    console.log("===");

    if (x == 1) {
      return await userService
        .logout(getters.userRefreshToken)
        .then((r) => {
          //console.log(r);
          dispatch("asw_log", ["logout response", r.data], { root: true });

          console.log(
            //getters.currentUsername +
            "---" +
              getters.userRefreshToken +
              "---[" +
              state.currentLoggedUser.name +
              "==" +
              state.currentLoggedUser.refreshToken +
              "]"
          );
          commit("LOG_OUT");
          console.log(
            //getters.currentUsername +
            "---" +
              getters.userRefreshToken +
              "---[" +
              state.currentLoggedUser.name +
              "==" +
              state.currentLoggedUser.refreshToken +
              "]"
          );
          if (getters.userRefreshToken != "") {
            console.log("WHAT THE FUCK !!!!");
          }

          n = {
            type: "success",
            message: "You are correctly logged out",
          };
          dispatch("notificationModule/add", n, { root: true });
        })
        .catch((error) => {
          dispatch("asw_log", ["logout error"], { root: true });
          n = {
            type: "error",
            message: "There was a problem with logout, erro:",
            error,
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        });
    }
  },

  //
  async registerTestUser({ commit, dispatch }, data) {
    var n = {};
    return await userService
      .registerTestUser(data)
      .then((r) => {
        dispatch("asw_log", "registerTestUser response", { root: true });
        commit("REGISTER_TEST_USER", r.data);
        n = {
          type: "success",
          message:
            "Your account has been created please check your email to confirm.",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", "registerTestUser error", { root: true });

        if (typeof error.response != "undefined") {
          if (error.response.status == 403 && error.response.data.status) {
            n = {
              type: "error",
              message: "Incorrect data. Please try one more time.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect data";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  // for some reason that i cant understand now getter.isUserLoggedIn is not checked each time when
  // this function is called !! i have to check it later one more time !! for now instead of using getters
  // im checking state dirrectly

  // isUserLoggedIn_Action_P({ dispatch, commit, getters, state }) {
  isUserLoggedIn_Action_P({ dispatch, commit, state }) {
    dispatch("asw_log", ["isUserLoggedIn_Action_P 0"], {
      root: true,
    });
    state.checkingState = 1;
    /*
    console.log("===>");
    console.log(state.currentLoggedUser.name);
    console.log(state.currentLoggedUser.refreshToken);
    console.log("===>");
	*/
    if (state.currentLoggedUser.refreshToken == "") {
      commit("SET_STATE_BASED_ON_LOCAL_STORAGE");
    }

    function iTV(expires) {
      //dispatch("asw_log", "iTV", { root: true });
      var d = new Date().getTime() + new Date().getTimezoneOffset() * 60000;
      if (typeof expires != "undefined") {
        if (expires > d) {
          return true;
        } else {
          return false;
        }
      }
    }

    //return new Promise((resolve, reject) => {
    return new Promise((resolve) => {
      //var iULI = getters.isUserLoggedIn;
      let updateToken = 0;

      if (iTV(state.currentLoggedUser.expires) === true) {
        if (state.currentLoggedUser.loggedIn === true) {
          resolve(true);
        } else {
          console.log(
            "isUserLoggedIn_Action_P we need to update token or relogin 0 ..."
          );
          updateToken = 1;
        }
      } else {
        /*
        console.log("------+++++");
        console.log(state.currentLoggedUser.expires);
        console.log(iTV(state.currentLoggedUser.expires));
        console.log(
          "isUserLoggedIn_Action_P we need to update token or relogin 1 ..."
        );
		*/
        updateToken = 1;
      }

      if (updateToken == 1) {
        /*
        console.log(
          "asw_log refresh token ... rt: " +
            state.currentLoggedUser.refreshToken
        );
		*/
        if (state.currentLoggedUser.refreshToken != "") {
          //
          //userService.refreshUserToken(getters.userRefreshToken)
          userService
            .refreshUserToken(state.currentLoggedUser.refreshToken)
            .then((r) => {
              dispatch("asw_log", ["response"], {
                root: true,
              });

              commit("SET_TOKEN", r.data);
              state.checkingState = 0;
              resolve(true);
            })
            .catch((error) => {
              dispatch("asw_log", ["ASW error::", error], { root: true });
              commit("LOG_OUT"); // << ??
              //reject(error);
              console.log(
                "probably becasue session token has been removed on server side!!"
              );
              state.checkingState = 0;
              resolve(false);
            });
          //
        } else {
          dispatch("asw_log", ["there is no refresh token ..."], {
            root: true,
          });
          // there is no refresh token ... no way you need to login man ...
          state.checkingState = 0;
          resolve(false);
        }
      }
    });
  },

  //
  async resetPasswordRequest({ commit, dispatch }, data) {
    var n = {};
    return await userService
      .resetPasswordRequest(data.email)
      .then((r) => {
        dispatch("asw_log", ["resetPasswordRequest response:", r], {
          root: true,
        });
        commit("REGISTER_TEST_USER", r.data);
        n = {
          type: "success",
          message: "Message with link to reset passord has been sent.",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", ["resetPasswordRequest error:", error], {
          root: true,
        });
        if (typeof error.response != "undefined") {
          if (error.response.status >= 400) {
            n = {
              type: "error",
              message: "Incorrect data. We dont have such a mail in our db.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect data";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  //
  async resetPassword({ commit, dispatch }, data) {
    var n = {};
    return await userService
      .resetPassword(data)
      .then((r) => {
        dispatch("asw_log", ["resetPassword response:", r], { root: true });
        commit("REGISTER_TEST_USER", r.data);
        n = {
          type: "success",
          message: "Message with link to reset passord has been sent.",
        };
        dispatch("notificationModule/add", n, { root: true });
      })
      .catch((error) => {
        dispatch("asw_log", ["resetPassword error:", error], { root: true });
        if (typeof error.response != "undefined") {
          if (error.response.status >= 400) {
            n = {
              type: "error",
              message: "Problem with saving password.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect data";
          }
        } else {
          n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },
};

/////////////
/*
function aswTimestampToString(t) {
  var timeStamp = t * 1;
  const d = new Date(timeStamp);

  var year = d.getFullYear();
  // month as 2 digits (MM)
  var month = ("0" + (d.getMonth() + 1)).slice(-2);

  // date as 2 digits (DD)
  var date = ("0" + d.getDate()).slice(-2);

  // hours as 2 digits (hh)
  var hours = ("0" + d.getHours()).slice(-2);

  // minutes as 2 digits (mm)
  var minutes = ("0" + d.getMinutes()).slice(-2);

  // seconds as 2 digits (ss)
  var seconds = ("0" + d.getSeconds()).slice(-2);

  // date & time as YYYY-MM-DD hh:mm:ss format:
  var r =
    year +
    "-" +
    month +
    "-" +
    date +
    " " +
    hours +
    ":" +
    minutes +
    ":" +
    seconds;
  return r;
}
*/

export { namespaced, state, getters, mutations, actions };
