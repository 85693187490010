//import axios from "axios";
import PageModule from "@/store/modules/generic/genericPageModule.js";
import clientService from "@/services/clientService.js";

var page = new PageModule();
const namespaced = true;

const state = {
  ...page.state,
  currentClient: {
    id: -1,
  },
  /*
  currentContext: {
    orderBy: "", // TODO: <<<
    searchParams: "", //TODO: <<<
    listColumns: "", //TODO: <<<
    pageNr: 0,
    id: 0, // fe what user was just edited etc...
    name: "",
  },
  */
};

const getters = {
  ...page.getters,
  getCurrentId(state) {
    return state.currentClient.id;
  },
  /*
  /// >>
  getCurrentId(state) {
    return state.currentContext.id;
  },
  getCurrentName(state) {
    return state.currentContext.name;
  },
  getCurrentPage(state) {
    return state.currentContext.pageNr;
  },
  getCurrentOrderBy(state) {
    return state.currentContext.orderBy;
  },
  getCurrentSearchParams(state) {
    return state.currentContext.searchParams;
  },
  getCurrentListColumns(state) {
    return state.currentContext.listColumns;
  },
  /// <<
  */
};

const mutations = {
  ...page.mutations,
  SET_CURRENTCLIENT_ID(state, clientId) {
    state.currentClient.id = clientId;
  },
  /*
  // >>>
  SET_CONTEXT_ID(state, usrId) {
    state.currentContext.id = usrId;
  },
  SET_CONTEXT_NAME(state, name) {
    state.currentContext.name = name;
  },
  SET_CONTEXT_PAGENR(state, pageNr) {
    state.currentContext.pageNr = pageNr;
  },
  SET_CONTEXT_ORDERBY(state, orderBy) {
    state.currentContext.orderBy = orderBy;
  },
  SET_CONTEXT_SEARCHPARAMS(state, searchParams) {
    state.currentContext.searchParams = searchParams;
  },
  SET_CONTEXT_LISTCOLUMNS(state, listColumns) {
    state.currentContext.listColumns = listColumns;
  },
  // <<<
  */
};

const actions = {
  ...page.actions,
  /*
  // <<<
  setCurrentId({ commit }, usrId) {
    commit("SET_CONTEXT_ID", usrId);
  },
  setCurrentName({ commit }, name) {
    commit("SET_CONTEXT_NAME", name);
  },
  setCurrentPage({ commit }, nrPage) {
    commit("SET_CONTEXT_PAGENR", nrPage);
  },
  setCurrentOrderBy({ commit }, orderBy) {
    commit("SET_CONTEXT_ORDERBY", orderBy);
  },
  setCurrentSearchParams({ commit }, searchParams) {
    commit("SET_CONTEXT_SEARCHPARAMS", searchParams);
  },
  setCurrentListColumns({ commit }, listColumns) {
    commit("SET_CONTEXT_LISTCOLUMNS", listColumns);
  },
  // <<<
  */

  async getClients({ dispatch }) {
    return clientService
      .getClients()
      .then((r) => {
        dispatch("asw_log", ["createStore] getClients response:", r.data], {
          root: true,
        });
      })
      .catch((error) => {
        if (typeof error.response != "undefined") {
          if (error.response.status == 403 && error.response.data.status) {
            const n = {
              type: "error",
              message: "Incorrect data. Please try one more time.",
            };
            dispatch("notificationModule/add", n, { root: true });
            throw "incorrect data";
          }
        } else {
          const n = {
            type: "error",
            message: "Probably network issue.",
          };
          dispatch("notificationModule/add", n, { root: true });
          throw error;
        }
      });
  },

  // ==============================================================================
  getClientList({ dispatch }, jsonObj) {
    return new Promise((resolve, reject) => {
      clientService
        .getClientList(jsonObj)
        .then((r) => {
          //vueApp.$_log("createStore] getClientList response:", r.data);
          dispatch(
            "asw_log",
            ["createStore] getClientList response:", r.data],
            {
              root: true,
            }
          );
          resolve(r.data);
        })
        .catch((error) => {
          //vueApp.$_log("ASW error:", error);

          if (typeof error.response != "undefined") {
            //vueApp.$_log("ASW error: 111:", error.response);
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },

  // ==============================================================================
  getClientListByParams({ dispatch }, jsonObj) {
    return new Promise((resolve, reject) => {
      clientService
        .getClientListByParams(jsonObj)
        .then((r) => {
          //vueApp.$_log("createStore] getClientList response:", r.data);
          dispatch(
            "asw_log",
            ["createStore] getClientList response:", r.data],
            {
              root: true,
            }
          );
          resolve(r.data);
        })
        .catch((error) => {
          //vueApp.$_log("ASW error:", error);

          if (typeof error.response != "undefined") {
            //vueApp.$_log("ASW error: 111:", error.response);
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },

  // ==============================================================================
  getClient({ dispatch }, Id) {
    return new Promise((resolve, reject) => {
      clientService
        .getClient(Id)
        .then((r) => {
          dispatch("asw_log", ["createStore] getClient response:", r.data], {
            root: true,
          });
          resolve(r.data);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            //vueApp.$_log("ASW error: 111:", error.response);
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },
};

export { namespaced, state, getters, mutations, actions };
