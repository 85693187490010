<template>
  <div v-if="totalPages > 0" class="tabPaginationContainer">
    <table>
      <tr>
        <td>
          <router-link
            id="page_prev"
            :to="{
              name: linkName,
              params: { pageNr: parseInt(pageNr) - 1 },
            }"
            rel="prev page"
            v-if="pageNr != 0"
            >&lt;&lt;</router-link
          >
        </td>
        <template v-if="totalPages > 10">
          <td>-</td>
          <td v-for="(p, i) in 10" :key="p">
            <template v-if="Math.floor(pageNr / 10) * 10 + i == pageNr">
              <p>[{{ Math.floor(pageNr / 10) * 10 + i + 1 }}]</p>
            </template>
            <template v-else>
              <template
                v-if="Math.floor(pageNr / 10) * 10 + i + 1 <= totalPages"
              >
                <router-link
                  :to="{
                    name: linkName,
                    params: {
                      pageNr: Math.floor(pageNr / 10) * 10 + i + 1,
                    },
                  }"
                  >{{ Math.floor(pageNr / 10) * 10 + i + 1 }}...</router-link
                >
              </template>
            </template>
          </td>
          <td>-</td>
        </template>
        <template v-else>
          <td v-for="(p, i) in totalPages" :key="p">
            <router-link
              v-if="i != pageNr"
              :to="{ name: linkName, params: { pageNr: i } }"
              >{{ p }}</router-link
            >
            <p v-else>[{{ p }}]</p>
          </td>
        </template>

        <td>
          <router-link
            id="page_next"
            :to="{
              name: linkName,
              params: { pageNr: parseInt(pageNr) + 1 },
            }"
            rel="next page"
            v-if="pageNr != totalPages - 1"
            >&gt;&gt;
          </router-link>
        </td>
        <td v-if="totalPages > 10">
          <!-- || {{ pageNr * 10 + 1 }} - {{ pageNr * 10 + 10 }} ||  -->
          go to page
          <!-- <BaseInput v-model="currentPage" label="go to page " type="text" />  -->
          <input
            name="goToPage"
            :value="this.pageNr + 1"
            @input="goToPage($event)"
            type="text"
            class="field"
          />

          of all {{ totalPages }} pages ||
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

//import BaseInput from "@/components/baseFormElements/BaseInput.vue";
//import BaseSelect from "@/components/baseFormElements/BaseInput.vue";

export default {
  name: "tabPagination",
  props: ["pageNr", "totalPages", "nrRowsTotal", "linkName", "nrEOP"],

  components: {
    //BaseInput,
    //BaseSelect,
  },
  data() {
    return {
      currentPage: null,
    };
  },
  created() {
    //this.currentPage = this.pageNr + 1;
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
  methods: {
    goToPage(event) {
      this.$_log(event.target.name + " - " + event.target.value);
      /*
      this.$emit("goToPage", {
        name: event.target.name,
        value: event.target.value,
      });
	  */
      console.log("ASW parseInt:" + parseInt(event.target.value, 10));
      if (!isNaN(parseInt(event.target.value, 10))) {
        this.currentPage = event.target.value;
        this.$router.push({
          name: this.linkName,
          //params: { id: Number(this.currentUserId) },
          params: { pageNr: this.currentPage - 1 },
        });
      }
    },
  },
};
</script>
<style scoped>
.tabPaginationContainer {
  display: flex;
  /*border: 1px solid red;*/
  /*width: 300px;*/
}
#page_prev {
  text-align: left;
}

#page_next {
  text-align: right;
}
</style>
