<template>
  <div class="gridAdminPanelContainer">
    <div class="gridTopSide">
      <!-- <router-link v-if="currentRouteName == 'register'" to="/"
        > -->
      <router-link v-if="!isUserLoggedIn" to="/">
        <div class="invisibleButton"
      /></router-link>
      <router-link v-else :to="dashboardPath"
        ><div class="invisibleButton"
      /></router-link>
    </div>
    <div class="gridNavPlaceholder">
      <div class="nav-title">Admin panel</div>

      <UserTopNavBar v-if="isUserLoggedIn && userType == 1" />
      <nav v-if="displayLoginButton" class="user_prof_nav">
        <router-link v-if="!isUserLoggedIn" to="/login" class="button"
          >login</router-link
        >

        <UserProfileNavBar v-else :title="currentUserEmail" />
      </nav>
    </div>
    <div class="gridSideNav"></div>
    <div class="gridMainContainer">
      <router-view />
    </div>
    <div class="gridBottomSide"></div>
    <div class="gridBottomPlaceholder"></div>
  </div>
</template>

<script>
import UserTopNavBar from "@/components/navigation/UserTopNavBar.vue";
import UserProfileNavBar from "@/components/navigation/UserProfileNavBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "AdminPanel",
  components: {
    UserProfileNavBar,
    UserTopNavBar,
  },
  props: {
    msg: String,
  },
  computed: {
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "currentUsername",
      "currentUserEmail",
      "userType",
      "currentUserId",
    ]),
    currentRouteName() {
      return this.$route.name;
    },
    dashboardPath() {
      return "/userdashboard/" + this.currentUserId;
    },
    displayLoginButton() {
      /*
      if (
        this.currentRouteName != "login" &&
        this.currentRouteName != "register" &&
        this.currentRouteName != "resetPasswordRequest" &&
        this.$route.name != "resetPassword"
      ){return true;}else{return false;}
	  */
      if (this.$route.meta.internalViewWithoutLogin == true) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    logout() {
      this.$_log("ASW lets logout!");
      this.$store
        .dispatch("userModule/logout")
        .then(() => {
          this.$_log("LoginDialog.vue] - user logged out !");

          this.$router.push({
            name: "home",
          });
        })
        .catch((error) => {
          //todo: use notification instead!
          this.$router.push({
            name: "ErrorDisplay",
            params: { error: error },
          });
        });
    },
  },
};
</script>

<style scoped>
/* ASW AdminPanel grid >> */
.gridAdminPanelContainer {
  position: relative;
  display: grid;
  grid-template-columns: 70px 1fr;
  grid-template-rows: 70px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row dense;
  grid-template-areas:
    "gridTopSide gridNavPlaceholder"
    "gridSideNav gridMainContainer";
  /*width: 99vw;*/
  width: -webkit-calc(100% - 1px);
  width: -moz-calc(100% - 1px);
  width: calc(100% - 1px);
  height: 99vh;
}

.gridTopSide {
  grid-area: gridTopSide;
  /*border: 3px solid #6e9438;*/
  position: absolute;
  background-image: url("@/assets/logo.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  width: 70px;
  height: 70px;
  box-shadow: 0px 0px 10px #0000001a;
  /*opacity: 1;*/
}

.gridNavPlaceholder {
  grid-area: gridNavPlaceholder;
  /*border: 3px solid #3e5b15;*/
  background: #68d061 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000014;
  opacity: 1;
}

.gridSideNav {
  grid-area: gridSideNav;
  /*border: 1px solid #dcd803;*/
}

.gridMainContainer {
  grid-area: gridMainContainer;
  background-color: #fafbff;
  /* border: 3px solid #b7b424; */
}

.gridBottomSide {
  grid-area: gridBottomSide;
  /* border: 3px solid #d87e10; */
}

/* ASW AdminPanel grid << */

.nav-title {
  position: absolute;
  top: 20px;
  left: 94px;
  text-align: left;

  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /*border: 3px solid #d87e10;*/
}

.user_prof_nav {
  position: absolute;
  width: fit-content;
  top: 20px;
  right: 20px;
  text-align: left;
  /*
  font: normal normal normal 15px/23px Poppins;
  letter-spacing: 0px;
  color: #f5f5f5;
  letter-spacing: 0px;
  */
  /*border: 3px solid #73ad21;*/
  display: block;
}

.invisibleButton {
  position: absolute;
  /* border: 3px solid #8d632f; */
  width: 65px;
  height: 65px;
}

/* ==== DROP DOWN MENU ====*/
/*
.my-dropdown-toggle {
  border-radius: 5px;

  ::v-deep .dropdown-toggle {
    color: tomato;
    font-size: 25px;
    font-weight: 800;
  }

  ::v-deep .dropdown-toggle-placeholder {
    color: #c4c4c4;
  }
}
*/

/* ====== topNavBar ====== */
.topNavBar {
  top: 20px;
  display: block;
  position: absolute;
  left: 50%;
  translate: -30%;
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /*border: 1px solid #8d632f;*/
}
.topNavBarItem {
  padding-left: 10px;
  padding-right: 10px;
}
</style>
