import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiLng = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getLngList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiLng.post("/admin/glngl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getLng(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiLng.get("/admin/glng/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createLng(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiLng.post("/admin/clng/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateLng(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiLng.put("/admin/ulng/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteLng(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiLng.delete("/admin/dlng/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
