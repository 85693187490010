<template>
  <div v-if="dataReady" class="userTypeOperationViewContainer">
    <listHeader
      pLabel="UserType_Operation"
      pLinkName="userTypeOperationAdd"
      dataSrc="UserType_Operation"
      :searchVars="searchVariables"
      @searchValues="Search"
    />
    <div class="tabDataContainer">
      <table>
        <!-- 
        <tr>
          <th>lp.</th>

          <th>UserType</th>
          <th>AMO end point</th>
          <th>AMO remarks</th>
          <th>Operation</th>
          <th>functions</th>
        </tr>
		-->

        <tabHeader
          :tableFields="tabFields"
          :fieldsToHide="tabFieldsToHide"
          :fieldsClass="tabFieldsClass"
          :fieldsTranslation="tabFieldsTranslations"
        />

        <tr
          v-for="(r, r_index) in tableContent"
          :key="r.id"
          :class="computedCssClass(r_index)"
        >
          <td>{{ r_index }}</td>

          <td>{{ r.ut_name }}</td>
          <td>{{ r.amo_remarks }}</td>
          <td>{{ r.amo_url }}</td>

          <td>{{ r.operation }}</td>
          <td>
            <router-link
              :to="{ name: 'userTypeOperationEdit', params: { id: r.id } }"
              >edit</router-link
            >
            /
            <a @click="deleteElement($event, r.id)" style="cursor: pointer">
              delete
            </a>
          </td>
        </tr>
      </table>
    </div>

    <pagination
      :pageNr="pageNr"
      :totalPages="totalPages"
      :nrRowsTotal="nrRowsTotal"
      linkName="userTypeOperationView"
      :nrEOP="nrElOnPage"
    />
  </div>
</template>

<script>
import { watchEffect } from "vue";
import { mapGetters, mapActions } from "vuex";

import utoService from "@/services/userTypeOperationService.js";
import pagination from "@/components/listView/tabPagination.vue";
import listHeader from "@/components/listView/listHeader.vue";

import useConfirmBeforeAction from "@/features/useConfirmBeforeAction.js";
import tabHeader from "@/components/listView/tabHeader.vue";

export default {
  name: "UserTypeOperation",

  props: ["msg", "pageNr"],
  components: {
    pagination,
    listHeader,
    tabHeader,
  },
  data() {
    return {
      usertype: null,
      tableName: "UserType_Operation",
      tableContent: null,

      tabFields: null,
      tabFieldsToHide: [],
      tabFieldsClass: [],
      tabFieldsTranslations: [],

      dataReady: false,

      // --> fields to be search
      searchVariables: {
        UserType_id: {
          label: "user type",
          value: null,
          type: "fk_int",
          dataSrc: "UserType",
          showInSearchBar: true,
          width: 10,
        },

        Operation_id: {
          label: "opreation",
          value: null,
          type: "fk_int",
          dataSrc: "Operation",
          showInSearchBar: true,
          width: 10,
        },
        remarks: {
          label: "amo remark",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
        endpoint: {
          label: "amo endpoint",
          value: null,
          type: "text",
          showInSearchBar: true,
          width: 10,
        },
      },
      // --< fields to be search

      nrRowsTotal: 0,
      nrElOnPage: 10,
      orderBy: "amo.id",
    };
  },
  created() {
    //this.$_log("TOKEN >>>>" + this.$store.state.userModule.currentLoggedUser.token);
    this.$_log("TOKEN >>>>" + this.userToken);

    if (this.isUserLoggedIn) {
      watchEffect(() => {
        this.getData();
      });
    }
  },
  computed: {
    totalPages() {
      return Math.ceil(this.nrRowsTotal / this.nrElOnPage);
    },
    ...mapGetters("userModule", ["userToken", "isUserLoggedIn"]),
  },
  methods: {
    callDeleteElem(elemId) {
      utoService
        .deleteUserTypeOperation(elemId)
        .then((response) => {
          this.$_log("asw response.data:", response.data);

          var n = {
            type: "success",
            message:
              "You just correctly removed module operation. (id:" +
              response.data.removedId +
              ")",
          };
          this.addNotificationMessage(n);
        })
        .then(() => {
          this.getData();
          /*
          this.$router.push({
            name: "userTypeOperationView",
            params: { pageNr: 0 },
          });
		  */
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
    deleteElement(event, elemId) {
      this.$_log("event:" + event);
      this.$_log("delete elem:" + elemId);
      useConfirmBeforeAction(
        function () {
          this.callDeleteElem(elemId);
        },
        function () {
          this.$_log("action canceled ...");
        },
        {
          question:
            "Do you realy want to remove UserType Operation '" + elemId + "' ?",
        }
      );
    },
    Search(data) {
      this.$_log("Search data:", data);
      this.searchVariables = data;
      this.getData();
    },
    computedCssClass(i) {
      if (i % 2 === 0) {
        return "";
      } else {
        return "odd";
      }
    },
    getData() {
      var jo = {
        // search params ->
        Operation_id: this.searchVariables.Operation_id.value,
        UserType_id: this.searchVariables.UserType_id.value,

        remarks: this.searchVariables.remarks.value,
        endpoint: this.searchVariables.endpoint.value,
        // search params <-
        pageNr: this.pageNr,
        nrElOnPage: this.nrElOnPage,
        orderBy: this.orderBy,
      };
      this.$_log("Just before getUserTypeOperationList....jo:", jo);

      utoService
        .getUserTypeOperationList(jo)
        .then((r) => {
          this.$_log("Just before getUserTypeOperationList....");
          this.$_log("created response:", r.data.data);

          this.nrRowsTotal = r.data.nrRowsTotal;
          this.tableContent = r.data.data;

          this.tabFields = r.data.fields;
          this.tabFieldsToHide.push(
            { name: "remarks" },
            { name: "ut_id" },
            { name: "amo_id" },
            { name: "amo_params" },
            { name: "amo_method" }
          );
          this.tabFieldsClass.push({ name: "remarks", class: "wideTabColumn" });
          this.tabFieldsTranslations.push({
            name: "functions",
            value: "FUNKCJE",
          });

          this.$_log(
            "created[" +
              this.tableName +
              "] this.tableContent.length:" +
              this.tableContent.length
          );

          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    },
    ...mapActions("dbToolsModule", ["getDictionaryTable"]),
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
  },
};
</script>

<style scoped></style>
