//import axios from "axios";
import PageModule from "@/store/modules/generic/genericPageModule.js";
import musicTagRelationsSrv from "@/services/musicTagRelationsSrv.js";

var page = new PageModule();

const namespaced = true;
// ==============================================================================
// ==============================================================================
const state = {
  ...page.state,
};
// ==============================================================================
// ==============================================================================
const getters = {
  ...page.getters,
};
// ==============================================================================
// ==============================================================================
const mutations = {
  ...page.mutations,
};
// ==============================================================================
// ==============================================================================
const actions = {
  ...page.actions,

  getMmpTagRelList({ dispatch }, jsonObj) {
    return new Promise((resolve, reject) => {
      musicTagRelationsSrv
        .getMmpTagRelList(jsonObj)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          resolve(r.data);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },

  getMmpTagRel({ dispatch }, Id) {
    return new Promise((resolve, reject) => {
      musicTagRelationsSrv
        .getMmpTagRel(Id)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          resolve(r.data);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },
};

// ==============================================================================
// ==============================================================================

export { namespaced, state, getters, mutations, actions };
