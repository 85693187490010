<template>
  <template v-if="dataReady">
    <router-view :id="id" />
  </template>
</template>
<script>
export default {
  name: "AppModuleLayout",
  props: ["id"],

  data() {
    return {
      AppModule: null,
      dataReady: false,
    };
  },
  created() {
    this.$_log("Just before AppModule.... AppModuleId:" + this.id);
    this.dataReady = true;
  },
};
</script>
<style scoped></style>
