<template>
  <div class="objListHeaderAdditional">
    <div class="functionElem">
      <button class="tabHeaderButton" @click="emitGetCognitoUsers($event)">
        import data from "muzaic.studio" cognito
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "tabHeaderAdditional",

  components: {},
  props: ["msg"],
  data() {
    return {
      i: 0,
    };
  },
  created() {
    this.$_log("received msg:" + this.msg);
  },
  methods: {
    emitGetCognitoUsers(event) {
      this.$_log(event.target.name + "" + event.target.value + " ...");
      this.$emit("getCognitoUsers");
    },
  },
};
</script>
<style>
.tabHeaderButton {
  background: #68d061 0% 0% no-repeat padding-box;

  height: 40px;
  border: double;
  border-radius: 8px;
  color: white;

  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
</style>
