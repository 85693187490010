import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiUser = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  /* ========= REQUIRE AUTHENTICATION ===========> */
  getAppModuleOperationList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.post("/admin/gamol/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  getAppModuleOperation(amolId) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.get("/admin/gamo/" + amolId, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  insertAppModuleOperation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.post("/admin/iamo/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  updateAppModuleOperation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.put("/admin/uamo/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  deleteAppModuleOperation(id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiUser.delete("/admin/damo/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
