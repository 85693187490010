<template>
  <!-- <div v-if="currentRouteName == 'login'"> -->
  <AdminPanel v-if="showAdminLayout" msg="" />
  <!-- </div> -->

  <!-- <div v-else> -->
  <div v-else class="LandingPageContainer">
    <div class="top-placeholder">
      <div class="tpl">
        <div class="tpl1">
          <router-link to="/"><div class="invisibleLogoButton" /></router-link>
        </div>
        <div class="tpl2">
          <div class="tpl_nav">
            <router-link to="/about">about</router-link>
          </div>
          <div class="tpl_nav">
            <router-link to="/facts">facts</router-link>
          </div>
          <div class="tpl_nav">
            <router-link to="/survey">survey</router-link>
          </div>

          <div class="tpl_nav">
            <router-link to="/register"
              ><div class="tryItBtn">try now</div></router-link
            >
          </div>
        </div>
        <div class="tpl3">
          <div class="tpl_nav">
            <router-link to="/login"
              ><div class="tryItBtn">login</div></router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div class="main-container">
      <router-view />
    </div>
    <div class="bottom-placeholder"></div>
  </div>
  <!-- </div> -->
</template>

<script>
import AdminPanel from "@/views/AdminPanel.vue";

export default {
  name: "LandingPage00",
  components: {
    AdminPanel,
  },
  props: {
    msg: String,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showAdminLayout() {
      if (
        this.$route.name == "login" ||
        this.$route.name == "register" ||
        this.$route.name == "resetPasswordRequest" ||
        this.$route.name == "resetPassword"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.LandingPageContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 70px 1fr;
  gap: 0px 0px;
  grid-auto-flow: row dense;
  grid-template-areas:
    "top-placeholder"
    "main-container";
  width: 99vw;
  height: 100vh;
}

.top-placeholder {
  grid-area: top-placeholder;
  position: relative;
  /* ---- */
  /*border: rgb(224, 86, 16) 2px dotted;*/
}

.main-container {
  position: relative;
  grid-area: main-container;
  background-image: url("@/assets/landingPage00/lpBg001.png"),
    url("@/assets/landingPage00/lpBg000.png");
  background-repeat: no-repeat, no-repeat;
  background-position: 110% 100px, 65% 100px;
  background-color: #fafbff;
  /* ---- */
  /*border: rgb(36, 20, 90) 2px dotted;*/
}

.tpl {
  position: relative;
  display: flex;
  width: 100%;
  top: 25px;

  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 1px;
  /* ---- */
  /*border: blue 1px solid;*/
}

.tpl a {
  font-weight: bold;
  color: #2c3e50;
}

.tpl a.router-link-exact-active {
  color: #42b983;
}

.tpl1 {
  /*border: 3px solid #6e9438;*/
  /*position: absolute;*/
  background-image: url("@/assets/logoName.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  /*width: 193px;
  height: 29px;
  box-shadow: 0px 0px 10px #0000001a;*/
  /*opacity: 1;*/

  width: 20%;
  /* ---- */
  /*border: rgb(58, 116, 87) 3px solid;*/
}
.tpl2 {
  width: 60%;
  /* ---- */
  /*border: rgb(168, 173, 4) 3px solid;*/
}

.tpl_nav {
  /*position: relative;*/
  display: inline-block;
  text-align: center;
  width: 140px;

  text-align: center;
  font: normal normal medium 17px/20px Poppins;
  letter-spacing: 0px;
  color: #1a1a1a;
  opacity: 1;

  /* ---- */
  /*border: rgb(168, 173, 4) 3px solid;*/
}

.tpl3 {
  width: 20%;
  /* ---- */
  /*border: rgb(24, 6, 116) 3px solid;*/
}

.invisibleLogoButton {
  /*position: absolute;*/
  /* border: 3px solid #8d632f; */
  width: 100%;
  height: 100%;
}

.tryItBtn {
  /*position: absolute;*/

  border: 2px solid #68d061;
  padding: 4px;
  width: 120px;
  /*height: 30px;*/

  top: 50%;
  left: 50%;
  margin: 0px 0 0 0px;

  color: white;

  background: transparent linear-gradient(109deg, #ced800 0%, #01c8c1 100%) 0%
    0% no-repeat padding-box;
  border-radius: 100px;
  box-shadow: none;
}

.tryItBtn:hover {
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  box-shadow: 2px 1000px 1px #fff inset;

  color: #68d061;
}
</style>
