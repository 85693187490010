<template>
  <div class="dlg_c_0">
    <ResetPasswordDialog
      v-if="!isUserLoggedIn"
      msg="Reset password."
      :rpToken="token"
    />

    <ResetPasswordDialog v-else msg="Change your password." :rpToken="token" />
  </div>
</template>

<script>
import ResetPasswordDialog from "@/components/dialogs/ResetPassword.vue";
import { mapGetters } from "vuex";

export default {
  name: "PasswordReset",
  components: {
    ResetPasswordDialog,
  },
  props: ["token"],
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
  },
};
</script>

<style scoped>
.dlg_c_0 {
  /*position: relative;*/
  text-align: center;
}
</style>
