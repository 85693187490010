<template>
  <div class="objListHeader">
    <div class="newElem">
      <button
        class="tabHeaderButton"
        @click="showAddNewDialog($event, pLinkName)"
      >
        add new
      </button>
    </div>
    |
    <div class="searchBar">
      <template v-for="(f, key, index) in searchVars" :key="index">
        <template v-if="f.showInSearchBar === true">
          <BaseSelectDB
            v-if="f.type == 'fk_int'"
            :listLabel="f.label"
            :srcTabName="f.dataSrc"
            :elName="key"
            :selected="f.value"
            :elSize="f.elSize ? f.elSize : 1"
            :elStyle="f.elStyle != '' ? f.elStyle : ''"
            @selectUpdated="onInfoFromSelect"
            :key="f.value"
          />

          <BaseInput v-else v-model="f.value" :label="f.label" :type="f.type" />
        </template>
      </template>
      <button class="tabHeaderButton" @click="clearForm()">clear</button>
      <button class="tabHeaderButton" @click="emitSearch()">search</button>
    </div>
  </div>
</template>

<script>
import BaseInput from "@/components/baseFormElements/BaseInput.vue";
import BaseSelectDB from "@/components/baseFormElements/BaseSelectDB.vue";

export default {
  name: "tabHeader",

  components: {
    BaseInput,
    BaseSelectDB,
  },
  props: ["pLabel", "pLinkName", "dataSrc", "searchVars"],
  data() {
    return {
      //BaseSelectDB_Key: 0,
      searchForm: null,
      i: 0,
    };
  },
  created() {
    this.searchForm = this.searchVars;
  },
  methods: {
    forceRerender() {
      //this.BaseSelectDB_Key += 1;
    },
    clearForm() {
      for (const [k, v] of Object.entries(this.searchForm)) {
        this.$_log(k + "---");
        this.$_log(v);

        if (v.type == "fk_int") {
          //console.log(document.getElementsByName[v.name]);
          this.$_log(this.$el.querySelector("." + k));

          this.$_log(this.$el.querySelector("." + k).selectedIndex);
          //this.$el.querySelector("." + k).selectedIndex = 0;
          //document.getElementsByClassName(k)[0].selected = undefined;
          this.$el.querySelector("." + k).selected = "";
          this.$_log(
            "==================================>>" +
              document.getElementsByClassName(k)[0].selectedIndex +
              "---" +
              this.$el.querySelector("." + k).selectedIndex
          );

          v.value = "";
          //this.forceRerender();
        } else {
          v.value = null;
        }
      }
    },
    onInfoFromSelect(v) {
      this.$_log("onInfoFromSelect v.name:" + v.name + " v.value:" + v.value);
      for (const [key, val] of Object.entries(this.searchForm)) {
        this.$_log(`${key}: ${val}`);
        if (key == v.name) {
          val.value = v.value;
        }
      }
    },
    showAddNewDialog(event, link) {
      this.$_log(event.target.name + "" + event.target.value + " link:" + link);

      this.$router.push({
        name: link,
      });
    },
    emitSearch() {
      this.$_log("this.searchVars::", this.searchForm);

      this.$emit("searchValues", this.searchForm);
    },
  },
};
</script>

<style scoped>
.objListHeader {
  display: block;
}
.newElem {
  display: inline-block;
}
.searchBar {
  display: inline-block;
}
.tabHeaderButton {
  background: #68d061 0% 0% no-repeat padding-box;
  width: 110px;
  height: 40px;
  border: double;
  border-radius: 8px;
  color: white;

  text-align: center;
  font-size: 16px;
  cursor: pointer;
}
</style>
