<template>
  <nav class="topNavBar">
    <div class="topNavBarItem">help center</div>
    <!--
    <dropdownMenu
      name="syrveyServices"
      title="questionnaire"
      :items="syrveyServices"
    />
	-->
    <div class="topNavBarItem">questionnaire</div>

    <dropdownMenu
      name="clientMenagement"
      title="menagement"
      :items="menagementServices"
    />

    <!--
	<router-link to="/streams/0/0/">
      <div class="topNavBarItem">streams</div>
    </router-link>
	-->

    <dropdownMenu
      name="translationServices"
      title="translations"
      :items="translationServices"
    />

    <dropdownMenu
      name="musicTagsServices"
      title="tags"
      :items="musicTagsServices"
    />
    <!-- <div class="topNavBarItem">tags</div> -->

    <dropdownMenu
      name="configMenagement"
      title="user & permitions"
      :items="configServices"
    />
  </nav>
</template>

<script>
import dropdownMenu from "@/components/navigation/DropdownMenu_Layout.vue";

export default {
  name: "UserTopNavBar",
  props: { title: String },
  data() {
    return {
      /////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////
      configServices: [
        {
          title: "users",
          link: "userView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "userView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "user types",
          link: "userTypes",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "userTypes",
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "users status",
          link: "userStatus",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "userStatuses",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "app modules",
          link: "appModules",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "appModules",
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "aplication module operations",
          link: "appModuleOperations",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "appModuleOperationView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "user type operations",
          link: "userTypeOperations",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "userTypeOperationView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        // +++++++++++++++++++++++++

        /*
        {
          title: "questionnaire",
          link: "surveysView",
          icon: "",
          fun: () => {
            this.$_log("ASW surveysView");
          },
        },
        
        {
          title: "languages",
          link: "lngView",
          icon: "",
          fun: () => {
            this.$_log("ASW languagesView");
            this.$router.push({
              name: "lngView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "Module Elements",
          link: "moduleElementView",
          icon: "",
          fun: () => {
            this.$_log("ASW moduleElementView");
            this.$router.push({
              name: "ModuleElementView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "Translations",
          link: "translationsView",
          icon: "",
          fun: () => {
            this.$_log("ASW translationsView");
          },
        },
		*/
        // +++++++++++++++++++++++++
      ],
      /////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////
      /*
      syrveyServices: [
        {
          title: "questionnaire",
          link: "surveysView",
          icon: "",
          fun: () => {
            this.$_log("ASW surveysView");
          },
        },
      ],
		*/

      /////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////
      musicTagsServices: [
        {
          title: "music tags",
          link: "MmpTagsView",
          icon: "",
          fun: () => {
            this.$_log("ASW MmpTagsView");
            this.$router.push({
              name: "MmpTagsView",

              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "tag categories",
          link: "MmpTagCatView",
          icon: "",
          fun: () => {
            this.$_log("ASW MmpTagCatView");
            this.$router.push({
              name: "MmpTagCatView",

              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "tag relations",
          link: "MmpTagRelView",
          icon: "",
          fun: () => {
            this.$_log("ASW MmpTagRelView");
            this.$router.push({
              name: "MmpTagRelView",

              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "tag relations - bulk",
          link: "MmpTagRelBlkView",
          icon: "",
          fun: () => {
            this.$_log("ASW MmpTagRelBlkView");
            this.$router.push({
              name: "MmpTagRelBlkView",

              params: { TagCatId: 0 },
            });
          },
        },
      ],
      /////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////

      translationServices: [
        {
          title: "languages",
          link: "lngView",
          icon: "",
          fun: () => {
            this.$_log("ASW languagesView");
            this.$router.push({
              name: "lngView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "Module Elements",
          link: "moduleElementView",
          icon: "",
          fun: () => {
            this.$_log("ASW moduleElementView");
            this.$router.push({
              name: "ModuleElementView",
              //params: { id: Number(this.currentUserId) },
              params: { pageNr: 0 },
            });
          },
        },
        {
          title: "Translations",
          link: "translationsView",
          icon: "",
          fun: () => {
            this.$_log("ASW translationsView");
            this.$router.push({
              name: "ModuleElementTranslationView",
              //params: { id: Number(this.currentUserId) },
              params: {
                pageNr: 0,
                // id: 0
              },
            });
          },
        },
      ],

      /////////////////////////////////////////////////////////////////////
      /////////////////////////////////////////////////////////////////////
      menagementServices: [
        {
          title: "clients",
          link: "clientsView",
          icon: "",
          fun: () => {
            this.$_log("ASW clientView");

            this.$router.push({
              name: "clientsView",
              //params: { id: Number(this.currentUserId) },
              params: {
                pageNr: 0,
                id: 0,
                // clientName: '', , remarks: '', country_id: 0
              },
            });
          },
        },
        {
          title: "locations",
          link: "locationsView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "locationsView",
              params: { pageNr: 0, ClientId: 0 },
            });
          },
        },
        {
          title: "zones",
          link: "zonesView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "zonesView",
              params: { pageNr: 0, ClientId: 0, LocationId: 0 },
            });
          },
        },

        {
          title: "streams",
          link: "streamsView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "streamsView",
              params: { pageNr: 0, ClientId: 0, LocationId: 0 },
            });
          },
        },
        {
          title: "schedules",
          link: "schedulesView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "schedulesView",
              params: { pageNr: 0, ClientId: 0, LocationId: 0, zoneId: 0 },
            });
          },
        },

        {
          title: "subscriptions",
          link: "subscriptionsView",
          icon: "",
          fun: () => {
            this.$router.push({
              name: "subscriptionsView",
              params: { pageNr: 0, ClientId: 0, LocationId: 0 },
            });
          },
        },
      ],
    };
  },
  components: {
    dropdownMenu,
  },
};
</script>

<style scoped>
.topNavBar {
  top: 20px;
  display: block;
  position: absolute;
  left: 50%;
  translate: -30%;
  font: normal normal 600 18px/27px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  /*border: 2px solid #8d632f;*/
}
.topNavBarItem {
  display: inline-block;
  text-align: left;
  font: normal normal 200 14px/21px Poppins;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0 0px 0 15px;
}
</style>
