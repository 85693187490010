import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiModuleElementTranslation = axios.create({
  baseURL: config.api_host, //"http://192.168.56.101:8008",
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  // =========================================================
  // =========================================================

  // =========================================================
  getModuleElementTranslationList(jsonData) {
    vueApp.$_log("===========================");
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleElementTranslation.post("/admin/gmdetl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
  // =========================================================
  getModuleElementTranslation(Id) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiModuleElementTranslation.get("/admin/gmdet/" + Id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  createModuleElementTranslation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleElementTranslation.post("/admin/cmdet/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  updateModuleElementTranslation(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleElementTranslation.put("/admin/umdet/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  // =========================================================
  deleteModuleElementTranslation(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiModuleElementTranslation.delete("/admin/dmdet/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
