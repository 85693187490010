<template>
  <div class="home">
    <HelloWorld msg="" />
  </div>
</template>

<script>
// @ is an alias to /src
//
import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  data() {
    return {
      x: 0,
    };
  },
  components: {
    HelloWorld,
  },
  created() {
    this.$_log("ASW home view ....this.x:" + this.x);
    //if (this.x == 0) location.reload();
  },
};
</script>
