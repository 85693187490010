<template>
  <label :for="uuid">{{ label }}</label>
  <input
    :value="modelValue"
    :type="type"
    :placeholder="placeholder"
    class="field"
    @input="$emit('update:modelValue', $event.target.value)"
    :id="uuid"
  />
</template>

<script>
import UniqueID from "@/features/UniqueID";

export default {
  props: {
    modelValue: { type: [String, Number], default: "" },
    label: { type: String, required: true },
    type: { type: [String], default: "text" },

    //name: { type: String, required: true },
    value: { type: [String, Number], default: "" },
    placeholder: { type: [String], default: "" },
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
};
</script>

<style scoped></style>
