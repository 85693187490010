import tb from "@/features/toolBox.js";
//var chalk = require("chalk");

export default {
  install: (app, options) => {
    // available for all components '$_log' method ('$' - is a naming convention)
    //just wrap function from features toolBox...
    app.config.globalProperties.$_log = tb._log;

    // just wrap function from features toolBox...
    app.config.globalProperties.$formatDate = tb.formatDate;

    app.config.globalProperties.$toHHMMSS = tb.toHHMMSS;
    app.provide("tb", options);
  },
};
