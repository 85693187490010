<template>
  <h4>
    Ooops! there was en error:
    <p>{{ error }}</p>
  </h4>
</template>

<script>
export default {
  props: ["error"],
};
</script>
