import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiAppModule = axios.create({
  baseURL: config.api_host,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  //
  getAppModuleList(jsonData) {
    vueApp.$_log("===========================.....");

    const userToken = GStore.getters["userModule/userToken"];
    return apiAppModule.post("/admin/gaml/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  getAppModule(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiAppModule.get("/admin/gam/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  createAppModule(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiAppModule.post("/admin/cam/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  updateAppModule(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];
    return apiAppModule.put("/admin/uam/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  deleteAppModule(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiAppModule.delete("/admin/dam/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
