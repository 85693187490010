var config = (module.exports = {});

//config.api_host = "http://192.168.56.101:8008"; // development system
config.api_host = "https://api.muzaic.biz/mbas_test"; // Production system

//later -->
//config.gui_host = "192.168.56.101";
//config.gui_port = "8080";

config.UniversalSocialauth_idProviders = {
  apple: {
    nonce: "**************",
    state: "**************",
    clientId: "**************",
    redirectUri: "http://localhost:8082/auth/apple/callback",
  },
  google: {
    clientId:
      "1069264840435-22s9311tnmh7in8h98qkr3he89u1t2cb.apps.googleusercontent.com",
    redirectUri: "https://gui.muzaic.biz/login/google/callback",
  },
  facebook: {
    clientId: "************",
    redirectUri: "http://localhost:8082/auth/facebook/callback",
  },
};
