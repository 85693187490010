import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import "nprogress/nprogress.css";
import * as ConfirmDialog from "vuejs-confirm-dialog"; //ASW dialog boxes ...
import asw_toolbox from "@/plugins/toolBox.js";

const vueApp = createApp(App)
  //  .use(Oauth)
  .use(asw_toolbox)
  .use(ConfirmDialog)
  .use(store)
  .use(router)
  .mount("#app");

// 2023-03 ASW => im exporting this object to be able to
// access global app variables and functions like '$_log'
// from components router and services...
export { vueApp };
