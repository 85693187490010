<template>
  <div v-if="dataReady">
    <label :for="uuid">{{ listLabel }}</label>

    <template v-if="readOnly">
      <input type="hidden" :name="elName" :value="selectedItem" />
      <p>{{ tableContent.find((el) => el.id == selectedItem).name }}</p>
    </template>
    <template v-else>
      <div class="idf_select">
        <select
          :id="uuid"
          v-model="selectedItem"
          :name="elName"
          @change="($event) => onChange($event)"
          :readonly="readOnly"
        >
          <option v-for="(t, index) in tableContent" :key="index" :value="t.id">
            {{ t.name }}
          </option>
        </select>
      </div>
    </template>
  </div>
</template>

<script>
import UniqueID from "@/features/UniqueID";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "DropDownList",
  props: {
    listLabel: String,
    srcTabName: String,
    selected: Number,
    elName: String,
    getData: Object,
    readOnly: Boolean,
    srcTabPar: String,
  },
  data() {
    return {
      tableName: "",
      tableContent: null,
      selectedItem: "",
      dataReady: false,
      par: "",
    };
  },
  setup() {
    const uuid = UniqueID().getID();
    return { uuid };
  },
  created() {
    this.$_log(
      "DropDownList.vue] get data according to the received params...:" +
        this.srcTabName
    );

    var multiRelationalData = 0;
    if (typeof this.getData == "object") {
      if (this.getData != "") {
        multiRelationalData = 1;
      }
    }
    if (typeof this.srcTabPar != "undefined") {
      if (this.srcTabPar != "") {
        this.par = this.srcTabPar;
      }
    }

    if (multiRelationalData == 0) {
      /**
	    this.getDictionaryTable(this.srcTabName)
        .then((r) => {
          this.$_log("DropDownList created response:", r.data);

          this.tableName = this.srcTabName;
          this.tableContent = r.data;
          this.$_log(
            "DropDownList created this.tableContent.length:" +
              this.tableContent.length
          );
          this.selectedItem = this.selected;
          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });	 
		 */
      this.getSTC({ tableName: this.srcTabName, par: this.par })
        .then((r) => {
          this.$_log("DropDownList created response:", r.data);

          this.tableName = this.srcTabName;
          this.tableContent = r.data;
          this.$_log(
            "DropDownList created this.tableContent.length:" +
              this.tableContent.length
          );
          this.selectedItem = this.selected;
          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    } else {
      this.$_log("getDataFrom more complicated table");
      const jD = {
        tableName: this.srcTabName,
        pageNr: 0,
        nrElOnPage: 1000,
        orderBy: "id",
        name: null,
        remarks: null,
      };
      this.$_log("getDataFrom more complicated table jD:", jD);

      this.getQueryData(jD)
        .then((r) => {
          //console.log("1] DropDownList created response:", r.data);
          //getData: { id: "id", value: ["url", "method"] },
          var T = [];
          for (var i = 0; i < r.data.length; i++) {
            var tmpObj = {};
            for (const [k, v] of Object.entries(r.data[i])) {
              if (k == this.getData.id) {
                //console.log("-----> k:" + k + " v:" + v);
                tmpObj.id = v;
                tmpObj.name = ""; // < 'name' becaue list is taking data from this object variable
              } else {
                for (var j = 0; j < this.getData.value.length; j++) {
                  if (k == this.getData.value[j]) {
                    //console.log("======> k:" + k + " v:" + v);
                    tmpObj.name = tmpObj.name + " - " + v;
                  }
                }
              }
            }
            //console.log("**********> tmpObj:", tmpObj);

            T.push(tmpObj);
          }

          this.tableName = this.srcTabName;
          this.tableContent = T;
          this.$_log(
            "1] DropDownList created this.tableContent.length:" +
              this.tableContent.length
          );
          this.selectedItem = this.selected;
          this.dataReady = true;
        })
        .catch((error) => {
          this.$_log(error);
        });
    }
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
    ...mapGetters("dbToolsModule", ["dictionary"]),
  },
  methods: {
    onChange(event) {
      //console.log(event.target.name + "" + event.target.value);

      this.$emit("fn001", {
        name: event.target.name,
        value: event.target.value,
        key: this.$.vnode.key, //"key-->" + this.kName,
      });
    },
    ...mapActions("dbToolsModule", [
      "getDictionaryTable",
      "getQueryData",
      "getSTC",
    ]),
  },
};
</script>

<style scoped></style>
