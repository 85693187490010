<template>
  <div class="lgn_c0">
    <LoginDialog msg="Log in" />
  </div>
</template>

<script>
import LoginDialog from "@/components/dialogs/LoginDialog.vue";

export default {
  name: "LoginView",
  components: {
    LoginDialog,
  },
};
</script>

<style scoped>
.lgn_c0 {
  position: relative;
  text-align: center;
}
</style>
