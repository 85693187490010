import axios from "axios";
import GStore from "@/store";
import config from "@/config/config.js";
import { vueApp } from "@/main.js";

const apiUserStatus = axios.create({
  baseURL: config.api_host,
  withCredentials: false,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    //Authorization: "Bearer " + GStore.getters["userModule/userToken"],
  },
});

export default {
  //
  getUserStatusList(jsonData) {
    vueApp.$_log("===========================");

    const userToken = GStore.getters["userModule/userToken"];
    return apiUserStatus.post("/admin/gusl/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  getUserStatus(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserStatus.get("/admin/gus/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  createUserStatus(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserStatus.post("/admin/cus/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  updateUserStatus(jsonData) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserStatus.put("/admin/uus/", jsonData, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },

  //
  deleteUserStatus(id) {
    const userToken = GStore.getters["userModule/userToken"];

    return apiUserStatus.delete("/admin/dus/" + id, {
      headers: {
        Authorization: "Bearer " + userToken,
      },
    });
  },
};
