<template>
  <template v-if="dataReady">
    <router-view :id="id" />
  </template>
</template>
<script>
export default {
  name: "UserLayout",
  props: ["id"],

  data() {
    return {
      User: null,
      dataReady: false,
    };
  },
  created() {
    this.$_log("Just before UserLayout.... userId:" + this.id);
    this.dataReady = true;
  },
};
</script>
<style scoped></style>
