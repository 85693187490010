import PageModule from "@/store/modules/generic/genericPageModule.js";
import streamService from "@/services/streamService.js";

var page = new PageModule();
const namespaced = true;

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const state = {
  currentStream: {
    id: -1,
    name: "",
    image: null,
    params: {
      intensity: null,
      tempo: null,
      rythm: null,
      tone: null,
      variation: null,
    },
    selectedTags: null,
    selectedMotives: null,
    selectedPlaylist: null,
    nowPlayingTrack: null,
  },
  receivedTags: null,
  receivedMotives: null,
  receivedPlaylists: null,

  ...page.state,
};

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const getters = {
  ...page.getters,

  curentStreamId(state) {
    return state.currentStream.id;
  },
  streamName(state) {
    return state.currentStream.name;
  },
  streamImage(state) {
    return state.currentStream.image;
  },
  streamParams(state) {
    return state.currentStream.params;
  },
  streamTags(state) {
    return state.currentStream.selectedTags;
  },
  streamMotives(state) {
    return state.currentStream.selectedMotives;
  },
  streamPlaylists(state) {
    return state.currentStream.selectedPlaylist;
  },
  nowPlayingTrack(state) {
    return state.currentStream.nowPlayingTrack;
  },

  receivedTags(state) {
    return state.receivedTags;
  },
  receivedMotives(state) {
    return state.receivedMotives;
  },
  receivedPlaylists(state) {
    return state.receivedPlaylists;
  },
};

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const mutations = {
  ...page.mutations,

  SET_CURRENT_Stream(state, clientData) {
    state.currentStream = clientData;
  },

  SET_STREAM_NAME(state, streamName) {
    state.currentStream.name = streamName;
  },
  SET_STREAM_IMAGE(state, streamImage) {
    state.currentStream.image = streamImage;
  },
  SET_STREAM_PARAMS(state, streamParams) {
    state.currentStream.params = streamParams;
  },
  SET_STREAM_TAGS(state, tagData) {
    state.currentStream.selectedTags = tagData;
  },
  SET_STREAM_MOTIVES(state, motiveData) {
    state.currentStream.selectedMotives = motiveData;
  },
  SET_STREAM_PLAYLISTS(state, playlistData) {
    state.currentStream.selectedPlaylist = playlistData;
  },

  SET_TAGS(state, tagData) {
    state.receivedTags = tagData;
  },
  SET_MOTIVES(state, motiveData) {
    state.receivedMotives = motiveData;
  },
  SET_PLAYLISTS(state, playlistData) {
    state.receivedPlaylists = playlistData;
  },
};

// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
// ///////////////////////////////////////////////////////////////////////////////////////
const actions = {
  ...page.actions,

  // =====================================================
  getStreamList({ dispatch }, jsonObj) {
    return new Promise((resolve, reject) => {
      streamService
        .getStreamsList(jsonObj)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          resolve(r.data);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },

  // =====================================================
  getStream({ dispatch }, Id) {
    return new Promise((resolve, reject) => {
      streamService
        .getSingleStream(Id)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          resolve(r.data);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              const n = {
                type: "error",
                message: "Incorrect data. Please try one more time.",
              };
              dispatch("notificationModule/add", n, { root: true });
              reject(error);
            }
          } else {
            const n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
            reject(error);
          }
        });
    });
  },

  // =====================================================
  setStreamPlaylists({ commit }, data) {
    commit("SET_STREAM_PLAYLISTS", data);
  },
  musicPlaylists({ commit, dispatch }, jsonData) {
    var n = {};
    return new Promise((resolve, reject) => {
      streamService
        .getStreamPlaylists(jsonData)
        .then((r) => {
          commit("SET_PLAYLISTS", r.data);
          n = {
            type: "success",
            message: "music playlists fetched.",
          };
          dispatch("notificationModule/add", n, { root: true });
          resolve(r);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              n = {
                type: "error",
                message: "Problem with fetching music tags.",
              };
              dispatch("notificationModule/add", n, { root: true });
            }
          } else {
            n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
          }
          reject(error);
        });
    });
  },
  setStreamMotives({ commit }, data) {
    commit("SET_STREAM_MOTIVES", data);
  },
  musicMotives({ commit, dispatch }, jsonData) {
    var n = {};
    return new Promise((resolve, reject) => {
      streamService
        .getStreamMotives(jsonData)
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          commit("SET_MOTIVES", r.data);
          n = {
            type: "success",
            message: "music motives fetched.",
          };
          dispatch("notificationModule/add", n, { root: true });
          resolve(r);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              n = {
                type: "error",
                message: "Problem with fetching music tags.",
              };
              dispatch("notificationModule/add", n, { root: true });
            }
          } else {
            n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
          }
          reject(error);
        });
    });
  },
  setStreamTags({ commit }, data) {
    commit("SET_STREAM_TAGS", data);
  },
  musicTags({ commit, dispatch }) {
    var n = {};
    return new Promise((resolve, reject) => {
      streamService
        .getStreamTags()
        .then((r) => {
          dispatch("asw_log", ["response:", r.data], {
            root: true,
          });
          commit("SET_TAGS", r.data);
          n = {
            type: "success",
            message: "music tags fetched.",
          };
          dispatch("notificationModule/add", n, { root: true });

          resolve(r);
        })
        .catch((error) => {
          if (typeof error.response != "undefined") {
            if (error.response.status == 403 && error.response.data.status) {
              n = {
                type: "error",
                message: "Problem with fetching music tags.",
              };
              dispatch("notificationModule/add", n, { root: true });
            }
          } else {
            n = {
              type: "error",
              message: "Probably network issue.",
            };
            dispatch("notificationModule/add", n, { root: true });
          }
          reject(error);
        });
    });
  },
};

export { namespaced, state, getters, mutations, actions };
