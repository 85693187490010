<template>
  <template v-if="dataReady">
    <div class="idf_container">
      <div class="idf_cl1">
        <p class="idf_header">
          <a @click="goBack">&lt;&lt;</a> {{ dlgType }} {{ headerTxt }}
          <span v-if="id">[id:{{ id }}]</span>
        </p>
      </div>
      <div class="idf_cl2">
        <form @submit.prevent="onSubmit">
          <fieldset>
            <legend>{{ legend }}</legend>
            <template v-for="(f, i) in currentObj" :key="f.name">
              <DropDownList
                v-if="currentObj[i].type == 'fk_int'"
                :key="'ftu_' + f.name + '_' + f.value"
                :listLabel="currentObj[i].label"
                :srcTabName="currentObj[i].dataSrc"
                :elName="currentObj[i].name"
                :selected="currentObj[i].value"
                :readOnly="
                  typeof currentObj[i].readOnly != 'undefined'
                    ? currentObj[i].readOnly
                    : false
                "
                :srcTabPar="
                  typeof f.srcTabPar != 'undefined' ? f.srcTabPar : ''
                "
                @fn001="onFn001"
              />

              <DropDownList
                v-else-if="currentObj[i].type == 'fk_multiRelational'"
                :key="f.name + '_' + f.value"
                :listLabel="currentObj[i].label"
                :srcTabName="currentObj[i].dataSrc"
                :getData="currentObj[i].getData"
                :elName="currentObj[i].name"
                :selected="currentObj[i].value"
                :readOnly="
                  typeof currentObj[i].readOnly != 'undefined'
                    ? currentObj[i].readOnly
                    : false
                "
                :srcTabPar="
                  typeof f.srcTabPar != 'undefined' ? f.srcTabPar : ''
                "
                @fn001="onFn001"
              />

              <DropDownSimpleList
                v-else-if="currentObj[i].type == 'list'"
                :key="f.name + '_' + f.value"
                :listLabel="currentObj[i].label"
                :srcTab="currentObj[i].dataSrc"
                :elName="currentObj[i].name"
                :selected="currentObj[i].value"
                :srcTabPar="
                  typeof f.srcTabPar != 'undefined' ? f.srcTabPar : ''
                "
                @fn001="onFn001"
              />

              <template
                v-else-if="
                  currentObj[i].type == 'password' &&
                  currentObj[i].name == 'password'
                "
              >
                <template v-if="dlgType == 'edit'">
                  <input
                    type="checkbox"
                    name="togglePasswords"
                    value="0"
                    @click="togglePasswordFields($event)"
                  />
                  change password
                </template>

                <PasswordInput
                  :label="currentObj[i].label"
                  :name="currentObj[i].name"
                  :value="currentObj[i].value"
                  :type="currentObj[i].type"
                  :placeholder="currentObj[i].value"
                  :error="currentObj[i].error"
                  :disabled="p_disabled == 1"
                  :style="p_style"
                />
              </template>
              <template
                v-else-if="
                  currentObj[i].type == 'password' &&
                  currentObj[i].name == 'password_confirmation'
                "
              >
                <PasswordInput
                  :label="currentObj[i].label"
                  :name="currentObj[i].name"
                  :value="currentObj[i].value"
                  :type="currentObj[i].type"
                  :placeholder="currentObj[i].value"
                  :error="currentObj[i].error"
                  :disabled="p_disabled == 1"
                  :style="p_style"
                />
              </template>
              <template
                v-else-if="
                  currentObj[i].type == 'text' && currentObj[i].name == 'email'
                "
              >
                <TextInput
                  :label="currentObj[i].label"
                  :name="currentObj[i].name"
                  :value="currentObj[i].value"
                  :type="currentObj[i].type"
                  :placeholder="currentObj[i].value"
                  :error="currentObj[i].error"
                />
              </template>
              <template v-else>
                <TextInput
                  :label="currentObj[i].label"
                  :name="currentObj[i].name"
                  :value="currentObj[i].value"
                  :type="currentObj[i].type"
                  :placeholder="currentObj[i].value"
                  error=""
                />
              </template>
            </template>
            <div class="idf_submit_line">
              <button type="submit" class="idf_submit_btn">Save</button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </template>
</template>

<script>
//import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import TextInput from "@/components/dialogs/formElements/TextInput.vue";
import PasswordInput from "@/components/dialogs/formElements/PasswordInput.vue";
import DropDownList from "@/components/dialogs/formElements/DropDownList.vue";
import DropDownSimpleList from "@/components/dialogs/formElements/DropDownSimpleList.vue";

export default {
  name: "GenericDialogForm",
  props: {
    headerTxt: String,
    objFields: Array,
    legend: String,
    dlgType: String,
    id: { type: Number, default: null },
  },
  components: {
    DropDownList,
    DropDownSimpleList,
    TextInput,
    PasswordInput,
  },
  data() {
    return {
      currentObj: this.objFields,
      dataReady: true,
      p_disabled: 1,
      p_style: "display: none;",
      ftu: 0,
    };
  },

  created() {
    this.$_log("genericDialog.vue] created ...", this.currentObj);
    if (this.dlgType == "add") {
      this.p_disabled = 0;
      this.p_style = "display: block;";
    }
    //this.dataReady = true;
  },
  computed: {
    //...mapState(['currentUser'])
    ...mapGetters("userModule", [
      "isUserLoggedIn",
      "userToken",
      "userRefreshToken",
      "currentUsername",
      "currentUserId",
    ]),
    ...mapGetters("dbToolsModule", ["dictionary"]),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    togglePasswordFields(e) {
      this.$_log(
        "togglePasswordFields elName:" +
          e.target.name +
          " elValue:" +
          e.target.checked +
          " disabled:" +
          this.p_disabled
      );
      //this.p_disabled = (this.p_disabled + 1) % 2;
      if (this.p_disabled == 0) {
        this.p_style = "display: none";
        this.p_disabled = 1;
        e.target.value = 0;
      } else {
        this.p_style = "display: block";
        this.p_disabled = 0;
        e.target.value = 1;
      }
    },
    onFn001(v) {
      this.$_log(
        "onFn001 v.name:" + v.name + " v.value:" + v.value + " key:" + v.key
      );
      for (let i = 0; i < this.currentObj.length; i++) {
        if (this.currentObj[i].name == v.name) {
          if (typeof this.currentObj[i].onUpdateUpdate != "undefined") {
            if (Array.isArray(this.currentObj[i].onUpdateUpdate)) {
              let T = this.currentObj[i].onUpdateUpdate;
              for (let j = 0; j < T.length; j++) {
                this.$_log("lets update dopdown:" + T[j]);
                /*
				let r = this.currentObj.filter((el) => el.name == T[j]);
                r.selected = v.value;
                this.$_log("selected:", r);
				 */
                for (let k = 0; k < this.currentObj.length; k++) {
                  if (this.currentObj[k].name == T[j]) {
                    this.currentObj[k].value = v.value;
                    this.currentObj[k].srcTabPar = " " + v.name + "=" + v.value;
                    this.$_log(
                      this.currentObj[k].selected +
                        "-->" +
                        this.currentObj[k].name +
                        "==>",
                      this.$keys
                    );
                  }
                }
              }
            }
          }
        }
      }
    },
    onChange(event) {
      this.$_log(
        "genericDialog.vue] onChange " +
          event.target.name +
          "" +
          event.target.value
      );
    },
    onSubmit(submitEvent) {
      this.$_log("genericDialog.vue onSubmit ");

      //collect data ->
      var s = 0;
      if (this.dlgType == "add") {
        s = 1;
      }
      for (var i = 0; i < this.currentObj.length; i++) {
        for (var j = 0; j < submitEvent.target.elements.length; j++) {
          if (submitEvent.target.elements[j].name == "togglePasswords") {
            if (submitEvent.target.elements[j].value == 1) {
              this.$_log("ok we CAN send passwords!!!");
              s = 1;
            }
          }

          if (this.currentObj[i].name == submitEvent.target.elements[j].name) {
            if (submitEvent.target.elements[j].name == "password" && s == 0) {
              this.$_log("do NOT send passwords!!!");
              this.currentObj[i].value = submitEvent.target.elements[j].value;
              this.currentObj[i].toBeSend = 0;
            } else {
              this.currentObj[i].value = submitEvent.target.elements[j].value;
            }
          }
        }
      }
      // collect data <-

      //emit data to parent component
      this.$emit("gdfOnSubmit", {
        formData: this.currentObj,
      });
    },

    //...mapActions("notificationModule", ["remove"]),
    //...mapActions("userModule", ["login"]),
    ...mapActions("dbToolsModule", ["getDictionaryTable"]),
  },
};
</script>

<style scoped>
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 28px;
  font-weight: 700;
  margin-top: 20px;
}
</style>
