<template>
  <div v-if="dataReady">
    <genericDialogForm
      dlgType="add"
      headerTxt="Module Operation"
      legend=""
      :objFields="objFields"
      @gdfOnSubmit="receiveFormValues"
    />
  </div>
</template>
<script>
import genericDialogForm from "@/components/dialogs/genericDialogForm.vue";
import utoService from "@/services/userTypeOperationService.js";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AppModuleOperationAdd",
  components: {
    genericDialogForm,
  },
  props: ["msg"],
  data() {
    return {
      objFields: [
        {
          name: "UserType_id",
          label: "user type",
          type: "fk_int",
          dataSrc: "UserType",
          value: "",
        },
        {
          name: "ApplicationModule_Operation_id",
          label: "app module operation",
          type: "fk_multiRelational",
          dataSrc: "ApplicationModule_Operation",
          getData: { id: "id", value: ["url", "method"] },
          value: "",
        },
      ],
      submitButton: { target: "" },
      dataReady: false,
    };
  },
  created() {
    this.$_log("views UserTypeOperation:add new");
    this.dataReady = true;
  },
  computed: {
    ...mapGetters("userModule", ["currentUserId"]),
  },
  methods: {
    ...mapActions("notificationModule", { addNotificationMessage: "add" }),
    receiveFormValues(fv) {
      this.$_log("receiveFormValues fv:", fv);
      this.doSubmit();
    },
    doSubmit() {
      // send data to the API server ...
      const obj = {
        UserType_id: this.objFields[0].value,
        ApplicationModule_Operation_id: this.objFields[1].value,
        user_created: this.currentUserId,
      };

      this.$_log("UserTypeOperation:add new doSubmit - objectToBeSent:", obj);

      utoService
        .insertUserTypeOperation(obj)
        .then((response) => {
          this.$_log("asw response.data:", response.data);
          //todo: add notification !!!

          var n = {
            type: "success",
            message:
              "You are correctly created new relation between usertype and application module operation.",
          };
          this.addNotificationMessage(n);

          this.$router.push({
            name: "userTypeOperationView",
            params: { pageNr: 0 },
          });
        })
        .catch((error) => {
          this.$_log("asw error:", error);
        });
    },
  },
};
</script>

<style scoped></style>
